import { MuiOtpInput } from "mui-one-time-password-input";
import { Controller, useFormContext } from "react-hook-form";

import { validation } from "../../Utils/validations";

export default function OTPField() {
  const { control } = useFormContext();

  return (
    <Controller
      name="otp"
      control={control}
      rules={{
        required: "OTP is required",
        validate: (otp) => validation.otp.isValidOTP(otp) || "Invalid OTP",
      }}
      render={({ field }) => (
        <MuiOtpInput
          {...field}
          length={6}
          autoFocus
          TextFieldsProps={{
            type: "number",
            inputProps: {
              inputMode: "numeric",
              pattern: "[0-9]*",
            },
          }}
        />
      )}
    />
  );
}
