import { useEffect, useMemo, useRef, useState } from "react";
import { CircularProgress, Stack } from "@mui/material";
import { useIntercom } from "react-use-intercom";

import ModalSkeleton from "../../../../Components/Skeleton/modal-skeleton";
import PrimaryButton from "../../../../Components/Buttons/primary-button";
import OutlineButton from "../../../../Components/Buttons/outline-button";

import renderVerifyToViewVirtualCard from "../verify-to-view-virtual-card";
import renderRequestPhysicalCard from "../../Manage Card/request-physical-card";
import renderStatusModal from "../../status-modal";

import { useModalContext } from "../../../../Contexts/ModalContext";
import { useViewport } from "../../../../Contexts/ViewportContext";

import { useStakeDebit } from "../../../../CustomHooks/Queries";

import {
  dispatchSuccessToast,
  getVGSFieldStyle,
  getVGSCopyButtonStyle,
  getVGSCardNumberRequestParams,
  getVGSCardNumberStyle,
  getVGSCvvRequestParams,
  hasSVDPhysicalCard,
  dispatchErrorToast,
  dispatchAPIErrorToast,
} from "../../../../Utils/helpers";
import { mediumScreenStartFrom, modalVariants } from "../../../../Utils/constants";

import { StakeDebitCardChip, StakeLogo, Visa } from "../../../../Assets/Images";
import { ArrowRightCircle, Eye } from "../../../../Assets/Icons";

import "./view-virtual-stake-debit.scss";

const ReportVirtualCardLostOrStolenModalProps = {
  title: "Report your virtual card lost\nor stolen",
  description: "To reset your virtual card number, please contact support",
  CTAButtonText: "Contact Support",
  secondaryCTAButtonText: "Cancel",
};

export default function renderVirtualStakeDebit(modalContext, props) {
  const { updateModalComponent, setModalVariant, setModalTitle, setModalClassName } = modalContext;

  updateModalComponent(() => <VirtualStakeDebit {...props} />);
  setModalTitle("Manage Virtual Card");
  setModalClassName("view-virtual-stake-debit");
  setModalVariant(modalVariants.stepByStepDialog);
}

const VirtualStakeDebit = (props) => {
  const { card_id, customer_token, environment, vault_id } = props;

  const { show: showIntercom } = useIntercom();

  const modalContext = useModalContext();
  const { width } = useViewport();

  const cardNumFrame = useRef(null);
  const cvvFrame = useRef(null);

  const [revealCardNumber, setRevealCardNumber] = useState("no");
  const [revealCvv, setRevealCvv] = useState("no");

  const { isLoading: isLoadingStakeDebit, data: stakeDebit } = useStakeDebit();

  const HasSVDPhysicalCard = useMemo(() => hasSVDPhysicalCard(stakeDebit?.data), [stakeDebit?.data]);

  const maskedCardNumber = useMemo(() => "**** **** **** " + stakeDebit?.data?.data?.virtual_card_last_4, [stakeDebit]);

  const expiryDate = useMemo(() => {
    const splitUpExpiryDate = stakeDebit?.data?.data?.virtual_card_expiration?.split("-");
    return `${splitUpExpiryDate[1]}/${splitUpExpiryDate[0] % 2000}`;
  }, [stakeDebit]);

  const onRequestError = (error) => {
    if (error?.status === 401) {
      dispatchErrorToast("Session expired. Please verify your phone number again.", { key: "request_error" });
      renderVerifyToViewVirtualCard(modalContext);
    } else {
      dispatchAPIErrorToast();
    }
  };

  const RevealCardNumber = () => {
    setRevealCardNumber("loading");

    const showCardNumber = window.VGSShow.create(vault_id).setEnvironment(environment);

    const cardNumberRequestNumberParams = {
      ...getVGSCardNumberRequestParams(card_id, customer_token),
      serializers: [showCardNumber.SERIALIZERS.replace("(\\d{4})(\\d{4})(\\d{4})(\\d{4})", "$1 $2 $3 $4")],
    };

    const cardNumber = showCardNumber.request(cardNumberRequestNumberParams);
    const cardNumberField = showCardNumber.request(cardNumberRequestNumberParams);

    cardNumber.render("#card-number", getVGSCardNumberStyle(width > mediumScreenStartFrom ? "32px" : "20px"));
    cardNumberField.render("#card-number-field", getVGSFieldStyle());

    const copyBtn = showCardNumber.copyFrom(
      cardNumberField,
      {
        text: "Copy",
        serializers: [showCardNumber.SERIALIZERS.replace(" ", "")],
      },
      (status) => {
        if (status === "success") dispatchSuccessToast("Copied to clipboard", { key: "card_number" });
      }
    );

    copyBtn.render("#copy-card-number", getVGSCopyButtonStyle());

    cardNumber.on("revealSuccess", () => setRevealCardNumber("yes"));
    cardNumber.on("requestFail", onRequestError);

    cardNumFrame.current = cardNumber;
  };

  const RevealCVV = () => {
    setRevealCvv("loading");

    const showCvv = window.VGSShow.create(vault_id).setEnvironment(environment);

    const cvv = showCvv.request(getVGSCvvRequestParams(card_id, customer_token));

    cvv.render("#cvv-field", getVGSFieldStyle());

    const copyBtn = showCvv.copyFrom(
      cvv,
      {
        text: "Copy",
        serializers: [showCvv.SERIALIZERS.replace(" ", "")],
      },
      (status) => {
        if (status === "success") dispatchSuccessToast("Copied to clipboard", { key: "cvv" });
      }
    );

    copyBtn.render("#copy-cvv", getVGSCopyButtonStyle());

    cvv.on("revealSuccess", () => setRevealCvv("yes"));
    cvv.on("requestFail", onRequestError);

    cardNumFrame.current = cvv;
  };

  useEffect(() => {
    const numFrame = cardNumFrame.current;
    const secretCvvFrame = cvvFrame.current;

    //cleaning up listeners
    return () => {
      numFrame?.off("revealSuccess");
      numFrame?.off("requestFail");
      secretCvvFrame?.off("revealSuccess");
      secretCvvFrame?.off("requestFail");
    };
  }, []);

  const onRevertBackToViewVirtualSDModal = () => renderVirtualStakeDebit(modalContext, props);

  return isLoadingStakeDebit ? (
    <ModalSkeleton />
  ) : (
    <>
      <div className="modal-image">
        <div className="stake-debit-card">
          <Stack direction="row" alignItems="flex-start" justifyContent="space-between">
            <StakeDebitCardChip className="card-chip" />
            <StakeLogo className="logo dark" />
          </Stack>
          {revealCardNumber !== "yes" && <div className="card-number">{maskedCardNumber}</div>}
          <div id="card-number" style={{ display: revealCardNumber !== "yes" ? "none" : "flex" }}></div>
          <Stack direction="row" alignItems="flex-end" justifyContent="space-between" gap="20px">
            <Stack direction="row" alignItems="flex-start" justifyContent="space-between" gap="20px">
              <div className="expiry-date">
                <span className="label">Expiry date</span>
                <span className="value mt-1">{expiryDate}</span>
              </div>
            </Stack>
            <Stack alignItems="flex-end" justifyContent="space-between">
              <div className="card-type">DEBIT</div>
              <Visa className="visa" />
            </Stack>
          </Stack>
        </div>
      </div>
      <div className="field my-6">
        <label>Debit Card Number</label>
        <div className="content">
          {revealCardNumber !== "yes" && <div className="value card-number-field">{maskedCardNumber}</div>}
          <div
            id="card-number-field"
            className="value"
            style={{ display: revealCardNumber !== "yes" ? "none" : "flex" }}
          ></div>
          {revealCardNumber === "no" && (
            <button className="field-icon" onClick={RevealCardNumber}>
              <Eye />
            </button>
          )}
          {revealCardNumber === "loading" && <CircularProgress className="field-icon" />}
          <button
            id="copy-card-number"
            className="field-icon copy"
            style={{ display: revealCardNumber !== "yes" ? "none" : "flex" }}
          ></button>
        </div>
      </div>
      <Stack direction="row" alignItems="flex-end" justifyContent="space-between" gap="32px">
        <div className="field occupy-width">
          <label>Expiration date</label>
          <div className="content">
            <div className="value cvv-field">{expiryDate}</div>
          </div>
        </div>
        <div className="field occupy-width">
          <label>CVV</label>
          <div className="content">
            {revealCvv !== "yes" && <div className="value cvv-field">***</div>}
            <div id="cvv-field" className="value" style={{ display: revealCvv !== "yes" ? "none" : "flex" }}></div>
            {revealCvv === "no" && (
              <button className="field-icon" onClick={RevealCVV}>
                <Eye />
              </button>
            )}
            {revealCvv === "loading" && <CircularProgress className="field-icon" />}
            <button
              id="copy-cvv"
              className="field-icon copy"
              style={{ display: revealCvv !== "yes" ? "none" : "flex" }}
            ></button>
          </div>
        </div>
      </Stack>
      <div className="action-container mt-8">
        <OutlineButton
          className="justify-content-between text-start responsive-font px-sm-6 px-4"
          onClick={renderStatusModal.bind(this, modalContext, {
            ...ReportVirtualCardLostOrStolenModalProps,
            onCTAButtonClick: () => {
              showIntercom();
              modalContext.onModalClose();
            },
            onSecondaryCTAButtonClick: onRevertBackToViewVirtualSDModal,
          })}
        >
          Report virtual card lost or stolen
          <ArrowRightCircle className="icon" />
        </OutlineButton>
        {!HasSVDPhysicalCard && (
          <PrimaryButton
            onClick={renderRequestPhysicalCard.bind(this, modalContext, {
              onCancel: onRevertBackToViewVirtualSDModal,
            })}
          >
            Request physical card
          </PrimaryButton>
        )}
      </div>
    </>
  );
};
