import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress, Divider } from "@mui/material";

import renderRemovePayroll from "../../Organisms/Modals/Direct Deposit/remove-payroll.js";
import renderReconnectPayroll from "../../Organisms/Modals/Direct Deposit/reconnect-payroll.js";

import usePinWheel from "../../CustomHooks/usePinWheel";

import { useModalContext } from "../../Contexts/ModalContext.js";

import { useStakeDebit } from "../../CustomHooks/Queries/index.js";

import { getTransformedPayrollAccounts } from "../../Utils/api-data-transforms.js";

import { getPayrollStatusIcon, isSVDApproved } from "../../Utils/helpers.js";

import { ArrowLeftCircle, AddCircle, Trash, Edit, AlertOctogon, Swap } from "../../Assets/Icons/index.js";

import "./direct-deposit.scss";
import "./direct-deposit-responsive.scss";

export default function DirectDeposit() {
  const [snoozeDisconnectedAlert, setSnoozeDisconnectedAlert] = useState(false);

  const navigate = useNavigate();

  const modalContext = useModalContext();

  const { isLoading: isLoadingStakeDebit, data: stakeDebit } = useStakeDebit();

  const IsSVDApproved = useMemo(() => isSVDApproved(stakeDebit?.data), [stakeDebit?.data]);

  const {
    payrollToken,
    payrollAccountsData,
    isFetchingPayrollAccounts,
    isGettingPayrollLinkToken,
    isGettingUpdatePayrollAccountToken,
    refetchingPayrollDataWithDebounce,
    updationAccountId,
    GetPayrollLinkToken,
    GetUpdatePayrollAccountToken,
    PinwheelComponent,
  } = usePinWheel(IsSVDApproved);

  const transformedPayrollAccounts = useMemo(
    () => getTransformedPayrollAccounts(payrollAccountsData?.data?.data?.accounts),
    [payrollAccountsData]
  );

  useEffect(() => {
    if (
      !snoozeDisconnectedAlert &&
      !modalContext.modalVisibility &&
      !isGettingUpdatePayrollAccountToken &&
      !payrollToken
    ) {
      const disconnectedPayroll = (transformedPayrollAccounts ?? []).find(
        (account) => account?.connection_status === "disconnected"
      );

      if (!!disconnectedPayroll) {
        renderReconnectPayroll(
          modalContext,
          disconnectedPayroll,
          () => {
            document.getElementById(disconnectedPayroll?.account_id).scrollIntoView();
            GetUpdatePayrollAccountToken(disconnectedPayroll?.account_id);
          },
          () => setSnoozeDisconnectedAlert(true)
        );
      }
    }
  }, [
    snoozeDisconnectedAlert,
    transformedPayrollAccounts,
    modalContext,
    isGettingUpdatePayrollAccountToken,
    payrollToken,
    GetUpdatePayrollAccountToken,
  ]);

  return (
    <div className="direct-deposit">
      <PinwheelComponent />
      <section className="card-section-wrapper">
        <div className="max-width-container">
          <div className="card-section">
            <div className="section-header">
              <button className="go-back-button">
                <ArrowLeftCircle onClick={navigate.bind(this, -1)} />
              </button>
              <h3 className="section-title">Direct Deposit</h3>
              {isGettingPayrollLinkToken ? (
                <CircularProgress />
              ) : (
                <button className={`add-button`} onClick={GetPayrollLinkToken}>
                  <AddCircle />
                </button>
              )}
            </div>
            {isLoadingStakeDebit || isFetchingPayrollAccounts || refetchingPayrollDataWithDebounce ? (
              <div className="loading-content flex-column">
                <CircularProgress />
                {refetchingPayrollDataWithDebounce && (
                  <span className="text-lg-medium text-center mt-2">
                    Please wait while we recollect payroll data ...
                  </span>
                )}
              </div>
            ) : transformedPayrollAccounts?.length > 0 ? (
              <div className="payrolls">
                {transformedPayrollAccounts?.map((account, idx) => {
                  const isDisconnected = account?.connection_status === "disconnected";
                  const isUpdatingAccount =
                    isGettingUpdatePayrollAccountToken && updationAccountId === account?.account_id;
                  const StatusIcon = getPayrollStatusIcon(account?.connection_status);

                  return (
                    <div key={idx} id={account?.account_id} className="payroll">
                      <div className="header">
                        <div className="payroll-details">
                          <img className="company-logo" src={account?.logo_url} alt="" />
                          <span className={`status-badge ${account?.connection_status}`}>
                            <StatusIcon className="badge-icon" />
                            {account?.connectionStatusLabel}
                          </span>
                        </div>
                        {!isDisconnected ? (
                          <button className="delete" onClick={renderRemovePayroll.bind(this, modalContext, account)}>
                            <Trash className="icon" />
                          </button>
                        ) : (
                          <AlertOctogon className="icon alert-icon" />
                        )}
                      </div>
                      <Divider />
                      <div className="allocation">
                        <div className="details">
                          <div className="amount">{account?.amountLabel}</div>
                          {!!account?.updatedOnLabel && <span className="updated-on">{account?.updatedOnLabel}</span>}
                        </div>
                        <button
                          className="update"
                          onClick={GetUpdatePayrollAccountToken.bind(this, account.account_id)}
                          disabled={isUpdatingAccount}
                        >
                          {isUpdatingAccount ? (
                            <CircularProgress />
                          ) : !isDisconnected ? (
                            <Edit className="icon" />
                          ) : (
                            <Swap className="icon" />
                          )}
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="payrolls no-records">No payroll connected yet</div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
}
