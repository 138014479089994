import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import { CircularProgress } from "@mui/material";

import AmountField from "../../Components/Form Fields/amount-field";
import PrimaryButton from "../../Components/Buttons/primary-button";
import FormSwitch from "../../Components/Form Fields/switch";

import renderAuthorizeTransfer from "../../Organisms/Modals/Banking/authorize-transfer";
import renderStatusModal from "../../Organisms/Modals/status-modal";
import renderAstraSignUp from "../../Organisms/Modals/Banking/astra-sign-up";
import renderMandateBankAccountAddition from "../../Organisms/Modals/Banking/mandate-bank-account-addition";

import useAstra from "../../CustomHooks/useAstra";

import withFormProvider from "../../HOC/withFormProvider";

import {
  useConnectedAccounts,
  useConnectedAccountSelection,
  useStakeDebit,
  useDeals,
  useGetRecurringDeposit,
} from "../../CustomHooks/Queries";
import {
  useInitiateACHDebit,
  useCreateTransferRoutine,
  useActivateRecurringDeposit,
} from "../../CustomHooks/Mutations";

import { useModalContext } from "../../Contexts/ModalContext";
import { useAppUtilityContext } from "../../Contexts/AppUtilityContext";

import { getTransferRoutinePayload } from "../../Utils/api-payload-generators";
import {
  getSelectedAstraCard,
  getActiveSVDAstraDebitCard,
  dispatchErrorToast,
  formatCurrency,
  getTransferTypes,
  isSVDApproved,
  isSelectedTransferMethodInstant,
  isSelectedTransferMethodBank,
  getAstraCardsWithSystemCardFiltered,
  formatDate,
} from "../../Utils/helpers";
import {
  FailedRecurringDepositStatusModalProps,
  FailedTransferStatusModalProps,
  nilYearLongMonthDateFormat,
  SuccessfulRecurringDepositStatusModalProps,
  SuccessfulTransferStatusModalProps,
} from "../../Utils/constants";

import { ArrowLeftCircle, Close, Edit } from "../../Assets/Icons";
import renderGetRecurringDepositPreferences from "../../Organisms/Modals/Recurring Deposits/get-recurring-deposit-preferences";

function AddCash() {
  const recurringDepositAttributesRef = useRef(null);

  const modalContext = useModalContext();

  const { onModalClose } = modalContext;

  const navigate = useNavigate();

  const { appUtilityProps, setAppUtilityProps } = useAppUtilityContext();

  const { enforceAddCash, onBackCallBack, onAbortCallBack, onAddCashSuccessful } = appUtilityProps?.addCash ?? {};

  const [selectedTransferType, setSelectedTransferType] = useState(appUtilityProps?.preferredTransferType ?? "ach");

  const { watch, handleSubmit } = useFormContext();

  const [isRecurringTransaction, setIsRecurringTransaction] = useState(false);

  const {
    isCreatingUserIntent: isAstraCreatingUserIntent,
    isLoadingAstraCards,
    openAstraPortal,
    astraDebitCards,
  } = useAstra();

  const { isLoading: isLoadingDeals, data: deals } = useDeals();

  const { isLoading: isLoadingStakeDebit, data: stakeDebit } = useStakeDebit();

  const { isLoading: isLoadingConnectedAccounts, data: connectedAccounts } = useConnectedAccounts();

  const { isLoading: isLoadingConnectedAccountSelection, data: connectedAccountSelection } =
    useConnectedAccountSelection();

  const {
    isLoading: isLoadingRecurringDeposit,
    data: recurringDeposit,
    refetch: refetchRecurringDeposit,
  } = useGetRecurringDeposit();

  const isAstraAccountConnected = useMemo(
    () => getAstraCardsWithSystemCardFiltered(astraDebitCards, stakeDebit).length > 0,
    [astraDebitCards, stakeDebit]
  );

  const isBankAccountConnected = useMemo(() => {
    return connectedAccounts?.data?.data?.accounts?.length > 0;
  }, [connectedAccounts?.data?.data?.accounts]);

  const selectedAccount = useMemo(
    () =>
      connectedAccounts?.data?.data?.accounts?.find(
        (account) => account.account_id === connectedAccountSelection?.data?.data?.selected
      ) ??
      connectedAccounts?.data?.data?.accounts?.[0] ??
      null,
    [connectedAccounts?.data?.data?.accounts, connectedAccountSelection?.data?.data?.selected]
  );

  const SVDApproved = useMemo(() => {
    return isSVDApproved(stakeDebit?.data);
  }, [stakeDebit?.data]);

  const dailyAddFundsLimit = useMemo(() => {
    const dailyLimit = (stakeDebit?.data?.data?.account_limits?.daily_add_cash_limit ?? 0) / 100;
    return dailyLimit;
  }, [stakeDebit]);

  const selectedAstraCard = useMemo(
    () => getSelectedAstraCard(astraDebitCards, stakeDebit, appUtilityProps?.selectedCardId),
    [astraDebitCards, stakeDebit, appUtilityProps?.selectedCardId]
  );

  const accountDescription = useMemo(() => {
    return !!selectedAccount ? `${selectedAccount?.name} (...${selectedAccount?.mask})` : "";
  }, [selectedAccount]);

  const instantAccountDescription = useMemo(() => {
    return !!selectedAstraCard ? `${selectedAstraCard?.card_company} (...${selectedAstraCard?.last_four_digits})` : "";
  }, [selectedAstraCard]);

  const transferTypes = useMemo(() => getTransferTypes(deals?.data), [deals]);

  useEffect(() => {
    if (!isLoadingStakeDebit && !SVDApproved) {
      navigate("/banking");
    }
  }, [isLoadingStakeDebit, SVDApproved, navigate]);

  const onAddCashSuccess = (description) =>
    renderStatusModal(modalContext, {
      ...SuccessfulTransferStatusModalProps,
      description,
      onCTAButtonClick:
        enforceAddCash && !!onAddCashSuccessful
          ? onAddCashSuccessful
          : () => {
              onModalClose();
              navigate("/banking");
            },
    });

  const onAddCashError = () =>
    renderStatusModal(modalContext, {
      ...FailedTransferStatusModalProps,
      onCTAButtonClick: () => onModalClose(),
    });

  const { mutate: InitiateACHDebit } = useInitiateACHDebit({
    onSuccess: () =>
      onAddCashSuccess(`Your transfer is expected to arrive on ${deals?.data?.content?.transaction_clearing_date}.`),
    onError: onAddCashError,
  });

  const { mutate: CreateTransferRoutine } = useCreateTransferRoutine({
    onSuccess: () => onAddCashSuccess("Your transfer is expected to arrive in a few minutes."),
    onError: onAddCashError,
  });

  const { mutate: ActivateRecurringDeposit } = useActivateRecurringDeposit({
    onSuccess: (_, v) => {
      renderStatusModal(modalContext, {
        ...SuccessfulRecurringDepositStatusModalProps,
        description: `Your first deposit is scheduled for ${formatDate(v.start_date, nilYearLongMonthDateFormat)}.`,
        onCTAButtonClick: () => {
          navigate("/banking/recurring-deposits");
          onModalClose();
        },
        onSecondaryCTAButtonClick: () => {
          navigate("/banking");
          onModalClose();
        },
      });
      refetchRecurringDeposit();
    },
    onError: () =>
      renderStatusModal(modalContext, {
        ...FailedRecurringDepositStatusModalProps,
        onCTAButtonClick: () => openAuthorizeTransferModal(),
        onSecondaryCTAButtonClick: () => onModalClose(),
      }),
  });

  const cashAmount = parseFloat(watch("cash-amount")?.unmasked ?? "");

  const disableSubmitButton = isNaN(cashAmount) || cashAmount === 0;

  const openAuthorizeTransferModal = () => {
    renderAuthorizeTransfer(modalContext, {
      stake_account_number_last4: stakeDebit?.data?.data?.account_number?.slice(-4),
      stake_routing_number: stakeDebit?.data?.data?.routing_number,
      ...(isSelectedTransferMethodInstant(selectedTransferType) ? { selectedAstraCard } : { selectedAccount }),
      clearingTransactionDate: deals?.data?.content?.transaction_clearing_date,
      amount: cashAmount,
      isRecurringDeposit: isRecurringTransaction,
      recurringDepositPreferences: recurringDepositAttributesRef.current?.preferences,
      AuthorizeCallback: () => {
        if (isSelectedTransferMethodInstant(selectedTransferType)) {
          const svdAstraCard = getActiveSVDAstraDebitCard(astraDebitCards, stakeDebit);
          CreateTransferRoutine(
            getTransferRoutinePayload({
              sourceCard: svdAstraCard,
              destinationCard: selectedAstraCard,
              amount: cashAmount,
            })
          );
        } else {
          if (isRecurringTransaction) {
            ActivateRecurringDeposit(recurringDepositAttributesRef.current?.preferences);
          } else {
            InitiateACHDebit({
              accountId: selectedAccount.account_id,
              amount: cashAmount,
            });
          }
        }
      },
    });
  };

  const onSubmit = () => {
    if (isRecurringTransaction) {
      recurringDepositAttributesRef.current = {
        preferences: {
          ...(recurringDepositAttributesRef.current.preferences ?? {}),
          amount: cashAmount,
          originating_account_id: selectedAccount.account_id,
        },
      };
    }

    if (
      (isSelectedTransferMethodBank(selectedTransferType) && !selectedAccount) ||
      (isSelectedTransferMethodInstant(selectedTransferType) && !selectedAstraCard)
    ) {
      dispatchErrorToast(
        isSelectedTransferMethodInstant(selectedTransferType)
          ? "Please add a debit card to proceed"
          : "Please add a bank account to proceed",
        {
          alignToAppContent: true,
          key: isSelectedTransferMethodInstant(selectedTransferType) ? "noAstraDebitCard" : "noConnectedAccount",
        }
      );
    } else if (dailyAddFundsLimit > 0 && cashAmount > dailyAddFundsLimit) {
      dispatchErrorToast("Amount cannot exceed the daily limit.", {
        alignToAppContent: true,
        key: "amountExceedsThreshold",
      });
    } else {
      openAuthorizeTransferModal();
    }
  };

  const handleBackButtonClick = () => {
    if (enforceAddCash && !!onBackCallBack) {
      onBackCallBack();
    } else {
      navigate(-1);
    }
  };

  const handleChangeTransferType = (selectedType) => {
    if (isSelectedTransferMethodInstant(selectedType) && !isAstraAccountConnected) {
      renderAstraSignUp(modalContext, openAstraPortal);
    }

    if (isSelectedTransferMethodInstant(selectedType) && isRecurringTransaction) {
      setIsRecurringTransaction(false);
    }

    setSelectedTransferType(selectedType);
    setAppUtilityProps((p) => ({
      ...p,
      preferredTransferType: selectedType,
    }));
  };

  const handleSelectPaymentEntity = () => {
    if (isSelectedTransferMethodInstant(selectedTransferType)) {
      setAppUtilityProps((p) => ({
        ...p,
        debitCards: { enforceCardSelection: true },
      }));
      navigate("/banking/debit-cards");
    } else {
      navigate("/banking/connected-accounts");
    }
  };

  const openGetRecurringDepositPreferencesModal = () =>
    renderGetRecurringDepositPreferences(modalContext, {
      onSubmit: ({ startDate, frequency }) => {
        recurringDepositAttributesRef.current = {
          preferences: {
            frequency_type: frequency,
            start_date: startDate,
          },
        };
        setIsRecurringTransaction(true);
        onModalClose();
      },
    });

  const handleToggleRecurring = () => {
    if (!isBankAccountConnected) {
      renderMandateBankAccountAddition(modalContext, {
        title: "Bank Account Required",
        description:
          "To set up a recurring deposit, you must first connect a bank account. Please add your bank account to continue.",
        onSuccess: openGetRecurringDepositPreferencesModal,
      });
    } else if (!isRecurringTransaction) {
      openGetRecurringDepositPreferencesModal();
    } else {
      setIsRecurringTransaction((p) => !p);
    }
  };

  const feeLabel = useMemo(() => {
    const feePercentage = deals?.data?.data?.currentDeal?.INSTANT_TRANSFER_OUT_OF_STAKE_FEE_RATE ?? 0.0175;

    if (isSelectedTransferMethodInstant(selectedTransferType) && isNaN(cashAmount)) {
      return `${(feePercentage * 100).toFixed(2)}%`;
    } else if (isSelectedTransferMethodInstant(selectedTransferType)) {
      return formatCurrency(cashAmount * feePercentage);
    } else {
      return "Free";
    }
  }, [deals, cashAmount, selectedTransferType]);

  const cashAmountIncludingFees = useMemo(() => {
    const feePercentage = deals?.data?.data?.currentDeal?.INSTANT_TRANSFER_INTO_STAKE_FEE_RATE ?? 0;

    if (isSelectedTransferMethodInstant(selectedTransferType)) {
      if (isNaN(cashAmount)) {
        return 0;
      }

      return cashAmount + cashAmount * feePercentage;
    } else {
      return cashAmount;
    }
  }, [cashAmount, selectedTransferType, deals]);

  const transferAmountLabel = useMemo(() => {
    if (isSelectedTransferMethodInstant(selectedTransferType)) {
      return `Add Cash ${formatCurrency(cashAmountIncludingFees)}`;
    } else {
      return isRecurringTransaction ? "Schedule" : "Add Cash";
    }
  }, [selectedTransferType, isRecurringTransaction, cashAmountIncludingFees]);

  const isLoading =
    isLoadingDeals ||
    isLoadingStakeDebit ||
    isLoadingConnectedAccounts ||
    isLoadingConnectedAccountSelection ||
    isLoadingAstraCards ||
    isLoadingRecurringDeposit;

  return (
    <div className={`add-cash${enforceAddCash ? " page-elevate" : ""}`}>
      <section className="card-section-wrapper">
        <div className="max-width-container">
          <div className="card-section">
            <div className="section-header">
              <button className="go-back-button" onClick={handleBackButtonClick}>
                <ArrowLeftCircle />
              </button>
              <h3 className="section-title">Add Cash</h3>
              {enforceAddCash && !!onAbortCallBack ? (
                <button className="close-button" onClick={onAbortCallBack}>
                  <Close />
                </button>
              ) : (
                <div className="dummy"></div>
              )}
            </div>
            {isLoading ? (
              <div className="loading-content">
                <CircularProgress />
              </div>
            ) : (
              <form className="content mt-sm-12 mt-6" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <div className="top-container">
                  <AmountField />
                  {!!dailyAddFundsLimit && (
                    <span className="daily-limit-label mt-2">Daily limit: {formatCurrency(dailyAddFundsLimit)}</span>
                  )}
                </div>
                <div className="bottom-container">
                  {deals?.data?.data?.currentDeal?.enabled_for_instant_transfer__c && (
                    <div className="transfer-types mb-6">
                      {transferTypes.map((transferType, idx) => (
                        <button
                          key={idx}
                          className={`transfer-type ${transferType.type}${
                            transferType.type === selectedTransferType ? " selected" : ""
                          }`}
                          type="button"
                          onClick={handleChangeTransferType.bind(this, transferType.type)}
                          disabled={isSelectedTransferMethodInstant(transferType.type) && isAstraCreatingUserIntent}
                        >
                          <span className="icon-wrapper">
                            <transferType.Icon className="icon" />
                          </span>
                          <div className="title">{transferType.title}</div>
                          {isSelectedTransferMethodInstant(transferType.type) && isAstraCreatingUserIntent ? (
                            <CircularProgress />
                          ) : (
                            <span className="description">{transferType.description}</span>
                          )}
                        </button>
                      ))}
                    </div>
                  )}
                  <div className="details">
                    <div className="detail-row">
                      <span className="detail-label">Account</span>
                      <span className="detail-value">
                        {isSelectedTransferMethodInstant(selectedTransferType)
                          ? !!selectedAstraCard
                            ? instantAccountDescription
                            : "Add Card"
                          : !!selectedAccount
                          ? accountDescription
                          : "Connect a bank account"}
                        <button className="edit" type="button" onClick={handleSelectPaymentEntity}>
                          <Edit />
                        </button>
                      </span>
                    </div>
                    <div className="detail-row">
                      <span className="detail-label">Fee</span>
                      <span className="detail-value">
                        {isSelectedTransferMethodInstant(selectedTransferType) ? feeLabel : "Free"}
                      </span>
                    </div>
                    {!isSelectedTransferMethodInstant(selectedTransferType) &&
                      !recurringDeposit?.data?.data?.active && (
                        <div className="detail-row">
                          <span className="detail-label">Make this recurring</span>
                          <FormSwitch checked={isRecurringTransaction} onClick={handleToggleRecurring} />
                        </div>
                      )}
                    <div className="detail-row">
                      <div className="info">
                        {isSelectedTransferMethodInstant(selectedTransferType)
                          ? "Transfer speed depends on your bank and could take up to 30 minutes."
                          : "2-3 business days. Transactions made after 3:00 PM ET or on a weekend or holiday will be processed the next business day."}
                      </div>
                    </div>
                  </div>
                  <PrimaryButton className="blue-theme mt-sm-6 mt-4" type="submit" disabled={disableSubmitButton}>
                    {transferAmountLabel}
                  </PrimaryButton>
                </div>
              </form>
            )}
          </div>
        </div>
      </section>
    </div>
  );
}

export default withFormProvider(AddCash);
