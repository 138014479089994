import PrimaryButton from "../../../Components/Buttons/primary-button";

import { modalVariants } from "../../../Utils/constants";

export default function renderAvailableBalance(modalContext) {
  const { updateModalComponent, setModalVariant, setModalTitle, onModalClose } =
    modalContext;

  updateModalComponent(() => (
    <>
      <span className="modal-content-text mb-6 mt-2">
        The total amount of money in your account that is available right now.
      </span>
      <div className="action-container">
        <PrimaryButton className="secondary-colors" onClick={onModalClose}>
          Close
        </PrimaryButton>
      </div>
    </>
  ));
  setModalVariant(modalVariants.infoDialog);
  setModalTitle("Available Balance");
}
