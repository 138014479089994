import SkeletonLoader from "../../Components/Skeleton/skeleton-loader";

import { Stack } from "@mui/material";

export default function RentDetailsSkeleton() {
    return (
        <div className="rent-details">
            <Stack className="gap-6"
                direction="row"
                alignItems="center"
                justifyContent="space-between"
            >
                <Stack className="content">
                    <Stack className="gap-2">
                        <SkeletonLoader className="border--rounded-full" width="100%" height={12} sx={{ maxWidth: '80px' }} />
                        <SkeletonLoader className="border--rounded-full" width="100%" height={8} sx={{ maxWidth: '160px' }} />
                    </Stack>
                    <Stack className="gap-2">
                        <SkeletonLoader className="border--rounded-full" width="100%" height={12} sx={{ maxWidth: '80px' }} />
                        <SkeletonLoader className="border--rounded-full" width="100%" height={8} sx={{ maxWidth: '160px' }} />
                    </Stack>
                </Stack>
                <SkeletonLoader className="border--rounded-sm image" width={144} height={144}/>
            </Stack>
        </div>
    );
}