import { useEffect } from "react";

import { isDevNodeEnv } from "./constants";

export default function HeapAnalytics() {
  useEffect(() => {
    // Check if the script is already injected to avoid duplicate scripts
    if (!isDevNodeEnv && !window.heap) {
      // Initialize Heap configuration
      window.heapReadyCb = window.heapReadyCb || [];
      window.heap = window.heap || [];
      window.heap.load = function (e, t) {
        window.heap.envId = e;
        window.heap.clientConfig = t = t || {};
        window.heap.clientConfig.shouldFetchServerConfig = !1;
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.async = true;
        script.src = `https://cdn.us.heap-api.com/config/${e}/heap_config.js`;
        const firstScript = document.getElementsByTagName("script")[0];
        firstScript.parentNode.insertBefore(script, firstScript);
        const functions = [
          "init",
          "startTracking",
          "stopTracking",
          "track",
          "resetIdentity",
          "identify",
          "getSessionId",
          "getUserId",
          "getIdentity",
          "addUserProperties",
          "addEventProperties",
          "removeEventProperty",
          "clearEventProperties",
          "addAccountProperties",
          "addAdapter",
          "addTransformer",
          "addTransformerFn",
          "onReady",
          "addPageviewProperties",
          "removePageviewProperty",
          "clearPageviewProperties",
          "trackPageview",
        ];
        const makeFn = function (fnName) {
          return function () {
            const args = Array.prototype.slice.call(arguments, 0);
            window.heapReadyCb.push({
              name: fnName,
              fn: function () {
                if (window.heap[fnName]) {
                  window.heap[fnName].apply(window.heap, args);
                }
              },
            });
          };
        };
        for (let i = 0; i < functions.length; i++) {
          window.heap[functions[i]] = makeFn(functions[i]);
        }
      };

      window.heap.load(process.env.REACT_APP_HEAP_APP_KEY);
    }
  }, []);

  return <></>;
}
