import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useIntercom } from "react-use-intercom";

import CardButton from "../../Components/Buttons/card-button";

import { useLogout } from "../../CustomHooks/Mutations";
import { useStakeDebit } from "../../CustomHooks/Queries/index.js";

import { onSignOut, isSVDApproved } from "../../Utils/helpers.js";

import { Disclosures, Terms, Privacy, ProfileOutline, SupportThin, Out, CircleQuestionThin } from "../../Assets/Icons";

export default function Profile() {
  const navigate = useNavigate();

  const { isLoading: isLoadingStakeDebit, data: stakeDebit } = useStakeDebit();

  const { show: showIntercom } = useIntercom();

  const { mutate: InitLogout } = useLogout({
    onSettled: onSignOut,
  });

  const isStakeDebitActive = useMemo(() => isSVDApproved(stakeDebit?.data), [stakeDebit]);

  return (
    <div className="profile">
      <section>
        <div className="max-width-container">
          <div className="section-header">
            <h3 className="section-title">Profile</h3>
          </div>
          <div className="mb-sm-5 mb-3">
            <CardButton
              title="Personal Information"
              Icon={ProfileOutline}
              onClick={navigate.bind(this, "/profile/personal-information")}
            />
          </div>
          <div className="mb-sm-5 mb-3">
            <CardButton title="Contact Support" Icon={SupportThin} onClick={showIntercom} />
          </div>
          {!isLoadingStakeDebit && isStakeDebitActive && (
            <div className="mb-sm-5 mb-3">
              <CardButton
                title="Banking Limits"
                Icon={SupportThin}
                onClick={navigate.bind(this, "/banking/banking-limits")}
              />
            </div>
          )}
          <div className="mb-sm-5 mb-3">
            <CardButton
              title="Privacy"
              Icon={Privacy}
              onClick={window.open.bind(this, "https://www.stake.rent/legal/privacy-policy", "_blank")}
            />
          </div>
          <div className="mb-sm-5 mb-3">
            <CardButton
              title="Terms"
              Icon={Terms}
              onClick={window.open.bind(this, "https://www.stake.rent/legal/terms-of-use", "_blank")}
            />
          </div>
          <div className="mb-sm-5 mb-3">
            <CardButton
              title="Disclosure"
              Icon={Disclosures}
              onClick={window.open.bind(this, "https://www.stake.rent/disclosure", "_blank")}
            />
          </div>
          <div className="d-lg-none mb-sm-5 mb-3">
            <CardButton
              title="FAQ"
              Icon={CircleQuestionThin}
              onClick={window.open.bind(this, "https://www.stake.rent/faq", "_blank")}
            />
          </div>
          <div className="d-lg-none mb-sm-5 mb-3">
            <CardButton className="logout" title="Logout" Icon={Out} onClick={InitLogout} />
          </div>
        </div>
      </section>
    </div>
  );
}
