import { useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Controller, useFormContext } from "react-hook-form";

import { Calendar as CalendarIcon, ChevronLeft, ChevronRight } from "../../Assets/Icons";

const fontFamilyTheme = createTheme({
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
  },
});

export default function DatePickerField({
  name,
  format = "MM/DD/YYYY",
  validations = {},
  datePickerProps = {},
  textFieldProps = {},
  miniField = false,
  defaultValue = null,
}) {
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: true,
        validate: validations,
      }}
      render={({ field: { onChange }, fieldState: { error } }) => {
        const handleChange = (v) => {
          if (v?.isValid()) {
            onChange(v.format("YYYY-MM-DD"));
          } else {
            onChange(v?._i ?? null);
          }
        };

        return (
          <ThemeProvider theme={fontFamilyTheme}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                control={control}
                format={format}
                defaultValue={defaultValue}
                open={openDatePicker}
                onChange={handleChange}
                onOpen={() => setOpenDatePicker(true)}
                onClose={() => setOpenDatePicker(false)}
                showDaysOutsideCurrentMonth
                slots={{
                  openPickerIcon: CalendarIcon,
                  leftArrowIcon: ChevronLeft,
                  rightArrowIcon: ChevronRight,
                }}
                slotProps={{
                  textField: {
                    ...textFieldProps,
                    readOnly: miniField,
                    error: !!error,
                    helperText: error?.message,
                    sx: getDatePickerTextFieldSxProp(miniField),
                    onClick: () => setOpenDatePicker(miniField),
                  },
                  openPickerButton: { disableRipple: true, sx: getOpenDatePickerButtonSxProp(miniField) },
                  toolbar: {
                    hidden: true,
                  },
                  popper: {
                    placement: "bottom-end",
                  },
                  desktopPaper: {
                    sx: paperSxProp,
                  },
                  mobilePaper: {
                    sx: paperSxProp,
                  },
                  calendarHeader: {
                    sx: calendarHeaderSxProp,
                  },
                  previousIconButton: { disableRipple: true },
                  nextIconButton: { disableRipple: true },
                  yearButton: { sx: yearSxProp },
                  day: { sx: daySxProp },
                  actionBar: { sx: actionBarSxProp },
                }}
                {...datePickerProps}
              />
            </LocalizationProvider>
          </ThemeProvider>
        );
      }}
    />
  );
}

const getDatePickerTextFieldSxProp = (miniField) => ({
  "& .MuiOutlinedInput-root": {
    "& .MuiOutlinedInput-input": {
      padding: miniField ? "10px 12px" : "12px",
      font: miniField
        ? "normal normal 500 12px/16px Poppins, sans-serif"
        : "normal normal 500 16px/24px Poppins, sans-serif",
      height: "auto",

      "&::placeholder": {
        color: "var(--medium-grey)",
        opacity: 1,
      },
    },
    "+ .MuiFormHelperText-root": {
      margin: "5px 12px 0",
      font: "normal normal 400 12px/16px Poppins, sans-serif",
      color: "var(--error-red)",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid var(--HR) !important",
    },
  },
});

const getOpenDatePickerButtonSxProp = (miniField) => ({
  height: miniField ? "16px" : "20px",
  width: miniField ? "16px" : "20px",
  padding: 0,
  marginRight: 0,
});

const calendarHeaderSxProp = {
  position: "relative",
  margin: 0,
  minHeight: "36px",
  borderBottom: "1px solid var(--HR)",
  paddingBottom: "46px",
  marginBottom: "12px",
  "& .MuiPickersArrowSwitcher-root": {
    width: 0,
  },
  "& .MuiPickersCalendarHeader-labelContainer": {
    margin: "auto",
    font: "normal normal 600 18px/24px Poppins, sans-serif",
    color: "var(--charcoal)",
  },
  "& .MuiPickersArrowSwitcher-button": {
    position: "absolute",
    top: "8px",
    bottom: 0,
    height: "20px",
    width: "20px",
    padding: 0,
    "&.Mui-disabled": { opacity: 0.5 },
  },
  "& .MuiPickersArrowSwitcher-previousIconButton": {
    left: 0,
  },
  "& .MuiPickersArrowSwitcher-nextIconButton": {
    right: 0,
  },
};

const paperSxProp = {
  padding: "20px",
  borderRadius: "var(--rounded-2xl)",
  border: "1px solid var(--HR)",
  background: "var(--white)",
  boxShadow: "0px 0px 7px 2px rgba(0, 0, 0, 0.05)",
  "& .MuiDateCalendar-root": {
    width: "auto",
    height: "329px",
    "& .MuiDayCalendar-header .MuiDayCalendar-weekDayLabel": {
      font: "normal normal 600 14px/20px Poppins, sans-serif",
      color: "var(--medium-grey)",
    },
    "& .MuiDayCalendar-slideTransition": {
      minHeight: "230px",
    },
    "& .MuiYearCalendar-root": { width: "280px" },
  },
};

const daySxProp = {
  font: "normal normal 500 14px/20px Poppins, sans-serif",
  "&.Mui-selected": { backgroundColor: "var(--stake-blue) !important" },
  "&.Mui-disabled:not(&.Mui-selected)": { color: "var(--medium-grey)" },
  "&.MuiPickersDay-today:not(&.Mui-selected)": { border: "none", color: "var(--stake-blue)" },
  "&.MuiPickersDay-dayOutsideMonth": { color: "var(--HR)" },
};

const yearSxProp = {
  font: "normal normal 500 14px/20px Poppins, sans-serif",
  width: "64px",
  "&.Mui-disabled": { color: "var(--medium-grey)" },
  "&.Mui-selected": { backgroundColor: "var(--stake-blue) !important" },
};

const actionBarSxProp = {
  padding: "8px 0 0",
  "& .MuiButton-root": {
    font: "normal normal 600 14px/20px Poppins, sans-serif",
    color: "var(--stake-blue)",
    padding: "6px 0",
  },
};
