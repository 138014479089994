import PropTypes from "prop-types";

export default function OutlineButton({ className = "", children, ...props }) {
  return (
    <button className={`outline-button ${className}`} {...props}>
      {children}
    </button>
  );
}

OutlineButton.prototype = {
  className: PropTypes.string,
  children: PropTypes.node,
};
