import { LocalStorage } from "./helpers";
import { appUtilityKeys } from "./constants";

export const getFetchAstraTokenPayload = (code = null) => {
  const user = LocalStorage.read(appUtilityKeys.loggedInUserData);
  const refreshToken = LocalStorage.read(appUtilityKeys.astraRefreshToken);

  const formData = new FormData();
  formData.append("code", code);
  formData.append("redirect_uri", process.env.REACT_APP_ASTRA_REDIRECT_URL);
  formData.append("grant_type", !!code ? "authorization_code" : "refresh_token");
  formData.append("refresh_token", !!code ? null : refreshToken);
  formData.append("user_id", user._id ?? "");

  return formData;
};

export const getTransferRoutinePayload = (attributes) => {
  const { sourceCard, destinationCard, amount } = attributes;

  const user = LocalStorage.read(appUtilityKeys.loggedInUserData);

  const payload = new FormData();

  if (!!sourceCard) {
    payload.append("source_id", sourceCard?.id ?? "");
  }

  payload.append("destination_id", destinationCard?.id ?? "");
  payload.append("destination_user_id", destinationCard?.user_id ?? "");
  payload.append("amount", amount);
  payload.append("user_id", user._id ?? "");

  return payload;
};
