import { CheckCircleOutline } from "../../../Assets/Icons";
import renderStatusModal from "../status-modal";

export default function renderAstraSignUpSuccess(modalContext) {
  const { onModalClose } = modalContext;

  renderStatusModal(modalContext, {
    StatusIcon: CheckCircleOutline,
    title: "Success! Astra is ready for transfers",
    description: "You can now start transferring funds through Astra.",
    CTAButtonText: "Next",
    onCTAButtonClick: onModalClose,
  });
}
