import { IntercomProvider } from "react-use-intercom";

import { useUserAccount } from "../CustomHooks/Queries";

export function SupportProvider({ children }) {
  const { data: userAccountData } = useUserAccount();

  return (
    <IntercomProvider
      appId={process.env.REACT_APP_INTERCOM_API_KEY}
      autoBoot={true}
      autoBootProps={{
        userId: userAccountData?.data?.data?.user?._id,
        name: `${userAccountData?.data?.data?.user?.firstName} ${userAccountData?.data?.data?.user?.lastName}`,
        email: userAccountData?.data?.data?.user?.email,
        phone: userAccountData?.data?.data?.user?.phoneNumber,
        hideDefaultLauncher: true,
      }}
    >
      {children}
    </IntercomProvider>
  );
}
