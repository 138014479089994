import { useMemo } from "react";

import ModalSkeleton from "../../../Components/Skeleton/modal-skeleton";
import PrimaryButton from "../../../Components/Buttons/primary-button";
import OutlineButton from "../../../Components/Buttons/outline-button";

import renderCardNeverArrived from "./card-never-arrived";
import renderAlertConfirmationModal from "../alert-confirmation-modal";
import renderActivateCard from "./activate-card";
import renderConfirmAddressToReplaceCard from "./confirm-address-to-replace-card";

import { useStakeDebit } from "../../../CustomHooks/Queries";

import { useModalContext } from "../../../Contexts/ModalContext";

import { formatDate, isSVDDebitCardActivated, hasSVDPhysicalCardArrived } from "../../../Utils/helpers";
import { modalVariants, nilYearLongMonthDateFormat } from "../../../Utils/constants";

import { StakeCardFrontSide } from "../../../Assets/Images";
import { ArrowRightCircle } from "../../../Assets/Icons";

export const ConfirmCardReplacementModalProps = {
  variant: "alert-neutral",
  title: "Are you sure you want to\nreplace your card?",
  description: "Your current card will be deactivated, and a new one will be mailed to you within 14 days.",
  CTAButtonText: "I’m sure",
  secondaryCTAButtonText: "Never mind",
};

export default function renderManageCard(modalContext) {
  const { updateModalComponent, setModalVariant, setModalTitle } = modalContext;

  updateModalComponent(ManageCard);
  setModalVariant(modalVariants.stepByStepDialog);
  setModalTitle("Manage Card");
}

const ManageCard = () => {
  const { isLoading: isLoadingStakeDebit, data: stakeDebit } = useStakeDebit();

  const physicalCardArrivesByDate = useMemo(
    () => new Date(stakeDebit?.data?.data?.physical_card_arrives_by ?? 0),
    [stakeDebit]
  );

  const formattedPhysicalCardArrivesByDate = useMemo(() => {
    return formatDate(physicalCardArrivesByDate, nilYearLongMonthDateFormat);
  }, [physicalCardArrivesByDate]);

  const HasSVDPhysicalCardArrived = useMemo(
    () => hasSVDPhysicalCardArrived(physicalCardArrivesByDate),
    [physicalCardArrivesByDate]
  );

  const isStakeDebitActivated = useMemo(() => isSVDDebitCardActivated(stakeDebit?.data), [stakeDebit?.data]);

  const modalContext = useModalContext();

  return isLoadingStakeDebit ? (
    <ModalSkeleton />
  ) : (
    <>
      <img className="stake-card-front-side mt-9" src={StakeCardFrontSide} alt="" />
      <div className="action-container mt-4">
        {!isStakeDebitActivated && (
          <>
            <span className="text-lg-medium color--medium-grey text-center mt-12">
              Your card will arrive by {formattedPhysicalCardArrivesByDate}.
            </span>
            <PrimaryButton className="blue-theme" onClick={renderActivateCard.bind(this, modalContext)}>
              Activate Card
            </PrimaryButton>

            {HasSVDPhysicalCardArrived && (
              <PrimaryButton className="secondary-colors" onClick={renderCardNeverArrived.bind(this, modalContext)}>
                My card never arrived
              </PrimaryButton>
            )}
          </>
        )}
        {isStakeDebitActivated && (
          <OutlineButton
            className="justify-content-between responsive-font px-sm-6 px-4 mt-12"
            onClick={renderAlertConfirmationModal.bind(this, modalContext, {
              ...ConfirmCardReplacementModalProps,
              onCTAButtonClick: renderConfirmAddressToReplaceCard.bind(this, modalContext),
            })}
          >
            Report card lost or stolen
            <ArrowRightCircle className="icon" />
          </OutlineButton>
        )}
      </div>
    </>
  );
};
