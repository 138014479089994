import PrimaryButton from "../../../Components/Buttons/primary-button";

import { useModalContext } from "../../../Contexts/ModalContext";

import renderAlertConfirmationModal from "../alert-confirmation-modal";
import renderConfirmAddressToReplaceCard from "./confirm-address-to-replace-card";

import { ConfirmCardReplacementModalProps } from "./manage-card";

import { modalVariants } from "../../../Utils/constants";

export default function renderCardNeverArrived(modalContext) {
  const { updateModalComponent, setModalVariant, setModalTitle } = modalContext;

  updateModalComponent(CardNeverArrived);
  setModalVariant(modalVariants.infoDialog);
  setModalTitle("Card never arrived?\nOrder a replacement");
}

const CardNeverArrived = () => {
  const modalContext = useModalContext();
  const { onModalClose } = modalContext;

  return (
    <>
      <span className="modal-content-text mt-2 mb-6">
        We suggest waiting a few days after the expected delivery date.
      </span>
      <span className="modal-content-text mb-sm-6 mb-5">
        Your other card will be deactivated, and a new one will be mailed to you within 14 days.
      </span>
      <div className="action-container">
        <PrimaryButton
          className="blue-theme"
          onClick={renderAlertConfirmationModal.bind(this, modalContext, {
            ...ConfirmCardReplacementModalProps,
            onCTAButtonClick: renderConfirmAddressToReplaceCard.bind(this, modalContext),
          })}
        >
          Order Replacement
        </PrimaryButton>
        <PrimaryButton className="secondary-colors" onClick={onModalClose}>
          Never Mind
        </PrimaryButton>
      </div>
    </>
  );
};
