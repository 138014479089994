import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";

import PrimaryButton from "../../../Components/Buttons/primary-button";

import useConnectBankAccount from "../../../CustomHooks/useConnectBankAccount";
import useAstra from "../../../CustomHooks/useAstra";

import { useModalContext } from "../../../Contexts/ModalContext";

import {
  dispatchAPIErrorToast,
  isSelectedTransferMethodBank,
  isSelectedTransferMethodInstant,
} from "../../../Utils/helpers";

import { BankIcon, AstraSmallIcon } from "../../../Assets/Images";

import "./no-payment-entity-found.scss";

export default function renderNoPaymentEntityFound(modalContext, transferType) {
  const { updateModalComponent, setModalTitle, updateModalOnCloseCallBack } = modalContext;

  updateModalComponent(() => <NoPaymentEntityFound transferType={transferType} />);
  setModalTitle(`Setup a payment method to ${transferType}`);
  updateModalOnCloseCallBack(() => setTimeout(() => (document.body.style.overflow = null), 250));
}

const NoPaymentEntityFound = ({ transferType }) => {
  const [selectedTransferMethod, setSelectedTransferMethod] = useState("ach");

  const navigate = useNavigate();

  const { onModalClose, setModalClassName } = useModalContext();

  const { isSavingAccount, plaidReady, openPlaid } = useConnectBankAccount({
    onSuccess: () => {
      navigate("/banking/connected-accounts");
      onModalClose();
    },
    onError: () => {
      dispatchAPIErrorToast({ alignToAppContent: true });
      onModalClose();
    },
    onInitiatingSave: () => setModalClassName(null),
    onExit: () => setModalClassName(null),
  });

  const AddBank = () => {
    if (!isSavingAccount && plaidReady) {
      setModalClassName("d-none");
      openPlaid();
    }
  };

  const { isCreatingUserIntent: isAstraCreatingUserIntent, openAstraPortal } = useAstra();

  const handleProceed = () => {
    if (isSelectedTransferMethodBank(selectedTransferMethod)) {
      AddBank();
    } else if (isSelectedTransferMethodInstant(selectedTransferMethod)) {
      onModalClose();
      openAstraPortal();
    }
  };

  const handleSkip = () => {
    onModalClose();
  };

  return (
    <>
      <p className="modal-content-text mt-2 mb-4">Choose a payment method to complete your {transferType}</p>
      <div className="payment-entity-options">
        <div
          className={`payment-entity-option ${isSelectedTransferMethodBank(selectedTransferMethod) ? "selected" : ""}`}
          onClick={setSelectedTransferMethod.bind(this, "ach")}
        >
          <div className="payment-entity">
            <BankIcon className="payment-entity-icon" />
            <div className="payment-entity-details">
              <h4 className="payment-entity-title">Standard transfer</h4>
              <span className="payment-entity-description">1-3 business days. Always free</span>
            </div>
          </div>
          <div
            className={`custom-radio-button  ${isSelectedTransferMethodBank(selectedTransferMethod) ? "checked" : ""}`}
          ></div>
        </div>
        <div
          className={`payment-entity-option ${
            isSelectedTransferMethodInstant(selectedTransferMethod) ? "selected" : ""
          }`}
          onClick={setSelectedTransferMethod.bind(this, "instant")}
        >
          <div className="payment-entity">
            <AstraSmallIcon className="payment-entity-icon" />
            <div className="payment-entity-details">
              <h4 className="payment-entity-title">Instant Transfer with Astra</h4>
              <span className="payment-entity-description">In a few minutes. 1.75% fee</span>
            </div>
          </div>
          <div
            className={`custom-radio-button  ${
              isSelectedTransferMethodInstant(selectedTransferMethod) ? "checked" : ""
            }`}
          ></div>
        </div>
      </div>
      <div className="action-container mt-sm-6 mt-5">
        <PrimaryButton
          className="blue-theme"
          onClick={handleProceed}
          disabled={isAstraCreatingUserIntent || isSavingAccount || !plaidReady}
        >
          Proceed {(isAstraCreatingUserIntent || isSavingAccount || !plaidReady) && <CircularProgress />}
        </PrimaryButton>
        <PrimaryButton className="secondary-colors" onClick={handleSkip}>
          Skip for Now
        </PrimaryButton>
      </div>
    </>
  );
};
