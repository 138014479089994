import axios from "axios";

import { APIErrorInterceptor, CoreAPIRequestInterceptor } from "../../src/Utils/helpers";

export const StakeAPIRequest = axios.create({ baseURL: process.env.REACT_APP_STAKE_API_BASE_URL });

export const CoreAPIRequest = axios.create({ baseURL: process.env.REACT_APP_CORE_API_BASE_URL });

CoreAPIRequest.interceptors.request.use(CoreAPIRequestInterceptor, null, { synchronous: true });

//Handles session expiry and unauthorised login
StakeAPIRequest.interceptors.response.use(null, APIErrorInterceptor, { synchronous: true });
CoreAPIRequest.interceptors.response.use(null, APIErrorInterceptor, { synchronous: true });
