import { useCallback, useEffect, useState } from "react";
import { useQueryClient } from "react-query";

import PrimaryButton from "../../../Components/Buttons/primary-button";

import { AlertConfirmationModal } from "../alert-confirmation-modal";
import renderOptedIn from "./Opted-In/opted-in";

import { useModalContext } from "../../../Contexts/ModalContext";

import { useDeals } from "../../../CustomHooks/Queries";
import { useOptInCreditReporting } from "../../../CustomHooks/Mutations";

import { dispatchAPIErrorToast } from "../../../Utils/helpers";
import { APIQueryKeys, modalVariants } from "../../../Utils/constants";

import { Credit_Builder_Opt_In } from "../../../Assets/Images";
import renderAddInformation from "../Personal Information/add-information";

export default function renderCreditBuilderOptIn(modalContext, { skipBreifing, optInOnMount } = {}) {
  const { updateModalComponent, setModalVariant, setModalTitle } = modalContext;

  updateModalComponent(() => <CreditBuilderOptIn skipBreifing={skipBreifing} optInOnMount={optInOnMount} />);
  setModalVariant(
    skipBreifing || optInOnMount ? modalVariants.alertConfirmationDialog : modalVariants.stepByStepDialog
  );
  setModalTitle("");
}

const CreditBuilderOptIn = ({ skipBreifing = false, optInOnMount = false }) => {
  const modalContext = useModalContext();
  const { setModalVariant } = modalContext;

  const queryClient = useQueryClient();

  const [step, setStep] = useState(skipBreifing || optInOnMount ? 4 : 1);

  const { isFetching: isFetchingDeals, data: deals } = useDeals();

  const {
    isLoading: isCreditReportingOptingIn,
    mutate: OptInCreditReporting,
    status: optInCreditReportingStatus,
  } = useOptInCreditReporting({
    onSuccess: () => {
      queryClient.refetchQueries(APIQueryKeys.creditReporting);
      renderOptedIn(modalContext);
    },
    onError: () => dispatchAPIErrorToast(),
  });

  const handleOptIn = useCallback(() => {
    const ssn_token = deals?.data?.data?.currentDeal?.ssn_token__c;
    const birthDate = deals?.data?.data?.currentDeal?.birthdate;

    if (!!ssn_token && !!birthDate) {
      OptInCreditReporting();
    } else {
      const requiredInfo = [];
      !ssn_token && requiredInfo.push("ssn");
      !birthDate && requiredInfo.push("dob");
      renderAddInformation(
        modalContext,
        {
          requiredInfo,
          onSuccess: () => renderCreditBuilderOptIn(modalContext, { optInOnMount: true }),
          onSecondaryCTAClick: () => renderCreditBuilderOptIn(modalContext, { skipBreifing: true }),
        },
        { userId: deals?.data?.data?.currentDeal?.sfid }
      );
    }
  }, [modalContext, OptInCreditReporting, deals]);

  useEffect(() => {
    if (optInOnMount && !isFetchingDeals && optInCreditReportingStatus === "idle") {
      handleOptIn();
    }
  }, [optInOnMount, isFetchingDeals, optInCreditReportingStatus, handleOptIn]);

  const handleNextButtonClick = () => {
    if (step < 4) {
      setStep((p) => p + 1);
      if (step === 3) {
        setModalVariant(modalVariants.alertConfirmationDialog);
      }
    } else {
      handleOptIn();
    }
  };

  const handleBackButtonClick = () => {
    if (step === 4) {
      setModalVariant(modalVariants.stepByStepDialog);
    }
    setStep((p) => p - 1);
  };

  return step === 4 ? (
    <AlertConfirmationModal
      variant="alert-neutral"
      title="Opt In"
      description="Your on-time rent payments will be reported to the credit bureaus. This change will take effect immediately."
      CTAButtonText="Confirm"
      secondaryCTAButtonText="Go Back"
      onCTAButtonClick={handleNextButtonClick}
      onSecondaryCTAButtonClick={handleBackButtonClick}
      isCTALoading={isCreditReportingOptingIn}
    />
  ) : (
    <>
      <div className="modal-image-carousel position-relative">
        <img src={Credit_Builder_Opt_In} alt="" />
        <div className="carousel-indicators">
          {Array.from(new Array(3)).map((_, idx) => (
            <button
              data-bs-target=""
              className={step === idx + 1 ? "active" : ""}
              onClick={() => setStep(idx + 1)}
            ></button>
          ))}
        </div>
      </div>
      <span className="modal-content-text highlight mt-1">
        {step === 1
          ? "Automatically boost your credit score by letting Stake report your on-time rent payments."
          : step === 2
          ? "There's no risk. Stake will only report your payments if they are on time. You can opt out at any time."
          : step === 3
          ? "Stake is a licensed data furnisher with the credit bureaus. We help you build credit, for free."
          : ""}
      </span>
      <div className="action-container mt-6">
        <PrimaryButton className="blue-theme" onClick={handleNextButtonClick}>
          {step === 3 ? "Opt In" : "Next"}
        </PrimaryButton>
        <PrimaryButton className="secondary-colors" onClick={handleBackButtonClick} disabled={step === 1}>
          Back
        </PrimaryButton>
      </div>
    </>
  );
};
