import { useMemo } from "react";

import { useModalContext } from "../../Contexts/ModalContext";

import renderRentMatch from "../../Organisms/Modals/Renting/rent-match";

import { formatCurrency, formatDate, getNextThirdTuesday } from "../../Utils/helpers";
import { nilYearLongMonthDateFormat } from "../../Utils/constants";

import { Help } from "../../Assets/Icons";

export default function MyReturnOnRent({ rentMatch }) {
  const modalContext = useModalContext();

  const nextThirdTuesday = useMemo(() => formatDate(getNextThirdTuesday(), nilYearLongMonthDateFormat), []);

  return (
    <div className="my-return-on-rent">
      <div className="my-return-on-rent-item">
        <div className="item-content">
          <span className="item-name">Pay on time</span>
          <span className="item-value">Earn {formatCurrency(rentMatch)}</span>
        </div>
        <button
          className="item-help-toggle"
          onClick={renderRentMatch.bind(this, modalContext, rentMatch, nextThirdTuesday)}
        >
          <Help />
        </button>
      </div>
    </div>
  );
}
