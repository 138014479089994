import SkeletonLoader from "../../Components/Skeleton/skeleton-loader";

export default function RentTrackerSkeleton() {
    return (
        <div className="rent-tracker-item">
            <div className="rent-tracker-header">
                {Array.from(new Array(3)).map((_, idx) => (
                    <SkeletonLoader key={idx} className="border--rounded-full" width="100%" height={8} sx={{ maxWidth: '80px' }} />
                ))
                }
            </div>
            {Array.from(new Array(2)).map((_, idx) => (
                <div key={idx} className="rent-tracker-row">
                    <SkeletonLoader className="border--rounded-full" width="100%" height={12} sx={{ maxWidth: '120px' }} />
                    <SkeletonLoader className="border--rounded-md status-icon" width={32} height={32} />
                    <SkeletonLoader className="border--rounded-md status-icon" width={32} height={32} />
                </div>
            ))
            }
        </div>
    );
}