import { useState } from "react";
import { useQueryClient } from "react-query";

import PrimaryButton from "../../../Components/Buttons/primary-button";

import { AlertConfirmationModal } from "../alert-confirmation-modal";
import renderStatusModal from "../status-modal";

import { useModalContext } from "../../../Contexts/ModalContext";

import { useOptOutCreditReporting } from "../../../CustomHooks/Mutations";

import { dispatchAPIErrorToast } from "../../../Utils/helpers";
import { APIQueryKeys, modalVariants } from "../../../Utils/constants";

import { Credit_Builder_Opt_Out } from "../../../Assets/Images";
import { CheckCircleOutline } from "../../../Assets/Icons";

export default function renderCreditBuilderOptOut(modalContext) {
  const { updateModalComponent, setModalVariant } = modalContext;

  updateModalComponent(() => <CreditBuilderOptOut />);
  setModalVariant(modalVariants.stepByStepDialog);
}

const CreditBuilderOptOut = () => {
  const modalContext = useModalContext();
  const { setModalVariant, onModalClose } = modalContext;

  const queryClient = useQueryClient();

  const [step, setStep] = useState(1);

  const { isLoading: isCreditReportingOptingOut, mutate: OptOutCreditReporting } = useOptOutCreditReporting({
    onSuccess: () => {
      queryClient.refetchQueries(APIQueryKeys.creditReporting);
      renderStatusModal(modalContext, {
        StatusIcon: CheckCircleOutline,
        title: "Success",
        description:
          "You have successfully opted out of Stake Credit Builder. We're sad to see you go. You can opt in again at any time.",
        CTAButtonText: "Continue",
        onCTAButtonClick: onModalClose,
      });
    },
    onError: () => dispatchAPIErrorToast(),
  });

  const handleNextButtonClick = () => {
    if (step < 2) {
      setStep((p) => p + 1);
      setModalVariant(modalVariants.alertConfirmationDialog);
    } else {
      OptOutCreditReporting();
    }
  };

  const handleBackButtonClick = () => {
    if (step === 2) {
      setModalVariant(modalVariants.stepByStepDialog);
    }
    setStep((p) => p - 1);
  };

  return step === 2 ? (
    <AlertConfirmationModal
      variant="alert-neutral"
      title="Opt Out"
      description="Your on-time rent payments will no longer be reported to the credit bureaus. Stake will no longer help you increase your credit score. This change will take effect immediately."
      CTAButtonText="Confirm"
      secondaryCTAButtonText="Go Back"
      onCTAButtonClick={handleNextButtonClick}
      onSecondaryCTAButtonClick={handleBackButtonClick}
      isCTALoading={isCreditReportingOptingOut}
    />
  ) : (
    <>
      <img className="modal-image" src={Credit_Builder_Opt_Out} alt="" />
      <span className="modal-content-text highlight mt-6">
        Stake is a licensed data furnisher with the credit bureaus. We help you build credit, for free.
      </span>
      <div className="action-container mt-6">
        <PrimaryButton className="blue-theme" onClick={handleNextButtonClick}>
          Opt Out
        </PrimaryButton>
      </div>
    </>
  );
};
