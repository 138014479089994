import { useIntercom } from "react-use-intercom";
import PrimaryButton from "../../../Components/Buttons/primary-button";

import { useModalContext } from "../../../Contexts/ModalContext";

import { modalVariants } from "../../../Utils/constants";

import { AlertOctogon } from "../../../Assets/Icons";

export default function renderRequestAddressUpdate(modalContext) {
  const { updateModalComponent, setModalVariant, setModalTitle, setModalClassName } = modalContext;

  updateModalComponent(() => <RequestAddressUpdate />);
  setModalVariant(modalVariants.infoDialog);
  setModalClassName();
  setModalTitle("");
}

const RequestAddressUpdate = () => {
  const modalContext = useModalContext();
  const { onModalClose } = modalContext;

  const { show: showIntercom } = useIntercom();

  const handleContactLiveChat = () => {
    showIntercom();
    onModalClose();
  };

  return (
    <>
      <AlertOctogon className="modal-status-icon mb-4" />
      <h3 className="modal-status-title mb-0">Contact Support</h3>
      <span className="modal-content-text mt-2 mb-6">Please contact our team, they will update your address info.</span>
      <div className="action-container">
        <PrimaryButton className="blue-theme" onClick={handleContactLiveChat}>
          Contact Live Chat
        </PrimaryButton>
      </div>
    </>
  );
};
