import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress, LinearProgress } from "@mui/material";

import { useStakeDebit } from "../../CustomHooks/Queries";

import { formatCurrency } from "../../Utils/helpers";

import { ArrowLeftCircle } from "../../Assets/Icons";

import "./banking-limits.scss";
import "./banking-limits-responsive.scss";

// Enum equivalent
const BankingLimitEnum = {
  StakeVisaDebit: { name: "Stake Visa Debit™", order: 1 },
  AddCash: { name: "Add Cash", order: 2 },
  CashOut: { name: "Cash Out", order: 3 },
  CheckDeposit: { name: "Check Deposit", order: 4 },
  ATMWithdrawal: { name: "ATM Withdrawal", order: 5 },
  ATMDeposit: { name: "ATM Deposit", order: 6 },
};

const safeLet = (a, b, fn) => (a != null && b != null ? fn(a, b) : null);

const BankingLimitCard = ({ bankingLimit = {} }) => {
  const title = useMemo(() => {
    switch (bankingLimit.type) {
      case "StakeVisaDebit":
        return "Stake Visa Debit™ spend";
      case "AddCash":
        return "Add Cash";
      case "CashOut":
        return "Cash Out";
      case "CheckDeposit":
        return "Check Deposit";
      case "ATMWithdrawal":
        return "ATM Withdrawal";
      case "ATMDeposit":
        return "ATM Deposit";
      default:
        return "";
    }
  }, [bankingLimit.type]);

  return (
    <div className="banking-limit-item mt-sm-6 mt-2">
      <div className="title">{title}</div>
      {title === "ATM Withdrawal" && <span className="limit-label">*includes money orders</span>}
      {bankingLimit.dailyLimit !== null && (
        <div className="day-limit mt-3">
          <div className="info">
            <span className="remaining-label">{formatCurrency(bankingLimit.dailyTotal)} remaining</span>
            <span className="limit-label">{formatCurrency(bankingLimit.dailyLimit)} per day</span>
          </div>
          <LinearProgress value={bankingLimit.dailyProgress} variant="determinate" />
        </div>
      )}
      {bankingLimit.monthlyLimit !== null && (
        <div className="month-limit mt-3">
          <div className="info">
            <span className="remaining-label">{formatCurrency(bankingLimit.monthlyTotal)} remaining</span>
            <span className="limit-label">{formatCurrency(bankingLimit.monthlyLimit)} per month</span>
          </div>
          <LinearProgress value={bankingLimit.monthlyProgress} variant="determinate" />
        </div>
      )}
    </div>
  );
};

function BankingLimits() {
  const navigate = useNavigate();

  const { isLoading: isLoadingStakeDebit, data: stakeDebit } = useStakeDebit({
    onSuccess: (data) => {},
    onError: () => console.error("Unable to obtain stake debit"),
  });

  const entries = useMemo(() => {
    const account_limits = stakeDebit?.data?.data?.account_limits;
    if (isLoadingStakeDebit || !account_limits) return [];

    return Object.entries(BankingLimitEnum)
      .sort(([, a], [, b]) => a.order - b.order)
      .map(([key, value]) => {
        let dailyLimit, dailyTotal, monthlyLimit, monthlyTotal;

        switch (key) {
          case "StakeVisaDebit":
            dailyLimit = account_limits.daily_svd_limit;
            dailyTotal = account_limits.daily_svd_total;
            monthlyLimit = null;
            monthlyTotal = null;
            break;
          case "AddCash":
            dailyLimit = account_limits.daily_add_cash_limit;
            dailyTotal = account_limits.daily_add_cash_total;
            monthlyLimit = account_limits.monthly_add_cash_limit;
            monthlyTotal = account_limits.monthly_add_cash_total;
            break;
          case "CashOut":
            dailyLimit = account_limits.daily_cash_out_limit;
            dailyTotal = account_limits.daily_cash_out_total;
            monthlyLimit = account_limits.monthly_cash_out_limit;
            monthlyTotal = account_limits.monthly_cash_out_total;
            break;
          case "CheckDeposit":
            dailyLimit = account_limits.daily_check_deposit_limit;
            dailyTotal = account_limits.daily_check_deposit_total;
            monthlyLimit = account_limits.monthly_check_deposit_limit;
            monthlyTotal = account_limits.monthly_check_deposit_total;
            break;
          case "ATMWithdrawal":
            dailyLimit = account_limits.daily_ATM_withdrawal_limit;
            dailyTotal = account_limits.daily_ATM_withdrawal_total;
            monthlyLimit = null;
            monthlyTotal = null;
            break;
          case "ATMDeposit":
            dailyLimit = account_limits.daily_ATM_deposit_limit;
            dailyTotal = account_limits.daily_ATM_deposit_total;
            monthlyLimit = null;
            monthlyTotal = null;
            break;
          default:
            dailyLimit = null;
            dailyTotal = null;
            monthlyLimit = null;
            monthlyTotal = null;
        }

        return {
          dailyTotal: dailyLimit !== null ? ((dailyLimit - dailyTotal) / 100).toFixed(2) : null,
          dailyLimit: dailyLimit !== null ? (dailyLimit / 100).toFixed(2) : null,
          dailyProgress: dailyLimit !== null ? (dailyTotal / dailyLimit) * 100 : null,
          monthlyTotal: safeLet(monthlyLimit, monthlyTotal, (limit, total) => ((limit - total) / 100).toFixed(2)),
          monthlyLimit: monthlyLimit !== null ? (monthlyLimit / 100).toFixed(2) : null,
          monthlyProgress: safeLet(monthlyLimit, monthlyTotal, (limit, total) => (total / limit) * 100),
          limitType: value.name,
          type: key,
        };
      });
  }, [isLoadingStakeDebit, stakeDebit?.data?.data?.account_limits]);

  return (
    <div className="banking-limits">
      <section className="card-section-wrapper">
        <div className="max-width-container">
          <div className="card-section">
            <div className="section-header">
              <button className="go-back-button">
                <ArrowLeftCircle onClick={() => navigate(-1)} />
              </button>
              <h3 className="section-title">Banking Limits</h3>
              <div className="dummy"></div>
            </div>
            {isLoadingStakeDebit ? (
              <div className="loading-content">
                <CircularProgress />
              </div>
            ) : (
              <div className="content">
                {entries.map((entry, index) => (
                  <BankingLimitCard key={index} bankingLimit={entry} />
                ))}
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
}

export default BankingLimits;
