import PropTypes from "prop-types";

export default function PrimaryButton({ className = "", children, ...props }) {
  return (
    <button className={`primary-button ${className}`} {...props}>
      {children}
    </button>
  );
}

PrimaryButton.prototype = {
  className: PropTypes.string,
  children: PropTypes.node,
};
