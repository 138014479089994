import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import ReactOdometer from "react-odometerjs";

import "../../Assets/Styles/odometer.scss";

const PadTrailingZeroWorkAround = (v) => v + 0.001;

export default function AmountOdometer({
  initialValue = 0.0,
  value,
  className = "",
  size = "44px",
  fontWeight = 500,
  color = "var(--text-primary)",
}) {
  const [odoValue, setOdoValue] = useState(PadTrailingZeroWorkAround(initialValue));

  useEffect(() => {
    const timeout = setTimeout(() => setOdoValue(PadTrailingZeroWorkAround(value)), 100);
    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <ReactOdometer
      className={`d-flex ${className}`}
      value={odoValue}
      format="(,ddd).ddd"
      style={{ height: size, fontSize: size, lineHeight: size, fontFamily: "Poppins, sans-serif", fontWeight, color }}
    />
  );
}

AmountOdometer.prototype = {
  initialValue: PropTypes.number,
  value: PropTypes.number.isRequired,
  className: PropTypes.string,
  size: PropTypes.string,
  fontWeight: PropTypes.number,
  color: PropTypes.string,
};
