import PropTypes from "prop-types";
import { useEffect, useState, useRef } from "react";

import PrimaryButton from "../../../Components/Buttons/primary-button";

import { LogoAnimation, StakeLogo, Vector } from "../../../Assets/Images";

import "./splash.scss";
import "./splash-responsive.scss";

export default function SplashScreen({ onCTATrigger }) {
  const buttonRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      if (buttonRef.current) {
        const buttonRect = buttonRef.current.getBoundingClientRect();
        const scrollToY = window.scrollY + buttonRect.bottom - window.innerHeight;
        window.scrollTo({ top: scrollToY, behavior: "smooth" });
      }
    }, 4600);
  }, []);

  const [stakeIconUnmountAnimate, setStakeIconUnmountAnimate] = useState(false);
  const [renderStakeIsStatement, setRenderStakeIsStatement] = useState(false);

  useEffect(() => {
    if (!stakeIconUnmountAnimate) {
      setTimeout(() => setStakeIconUnmountAnimate(true), 2000);
    } else if (!renderStakeIsStatement) {
      setTimeout(() => setRenderStakeIsStatement(true), 750);
    }
  }, [stakeIconUnmountAnimate, renderStakeIsStatement]);

  return (
    <div className={`onboarding-page splash ${!renderStakeIsStatement ? "justify-content-center" : ""}`}>
      {!renderStakeIsStatement && <img className="logo-animation" src={LogoAnimation} alt="" />}
      {renderStakeIsStatement && (
        <>
          <header>
            <StakeLogo className="logo" />
          </header>
          <div className="splash-content max-width-container">
            <div className="stake-is animate-in-order order-1">Stake is</div>
            <div className="cash animate-in-order order-2">Cash</div>
            <div className="back animate-in-order order-2">Back</div>
            <Vector className="resize-on-screen-height vector animate-in-order order-4" />
            <div className="on animate-in-order order-3">On</div>
            <div className="rent animate-in-order order-3">Rent</div>
            <div className="much-more animate-in-order order-4">& Much More</div>
          </div>
          <div className="max-width-container" ref={buttonRef}>
            <PrimaryButton className="splash-cta-button my-6" onClick={onCTATrigger}>
              Next
            </PrimaryButton>
          </div>
        </>
      )}
    </div>
  );
}

SplashScreen.prototype = { onCTATrigger: PropTypes.func };
