import SkeletonLoader from "./skeleton-loader";
import { Divider } from "@mui/material";

export default function BankActionButtonsSkeleton() {
    return (
        <div className="banking-action-buttons skeleton">
          <SkeletonLoader className="border--rounded-lg bg--loader-bg-dark-secondary" width="100%" height={56} />
          <Divider orientation="vertical" flexItem />
          <SkeletonLoader className="border--rounded-lg bg--loader-bg-dark-secondary" width="100%" height={56} />
        </div>
    );
}