import { RecurringDepositsFormWrapped } from "../../../Pages/Recurring Deposits";

import { useModalContext } from "../../../Contexts/ModalContext";

import { modalVariants } from "../../../Utils/constants";

import { Close } from "../../../Assets/Icons";

export default function renderGetRecurringDepositPreferences(modalContext, props) {
  const { updateModalComponent, setModalVariant } = modalContext;

  updateModalComponent(() => <GetRecurringDepositPreferences {...props} />);
  setModalVariant(modalVariants.pageAsModalComponentDialog);
}

export const GetRecurringDepositPreferences = (props) => {
  const { onModalClose } = useModalContext();

  return (
    <>
      <div className="section-header">
        <div className="dummy"></div>
        <h3 className="section-title">Recurring Deposit</h3>
        <button className="close-button" onClick={() => onModalClose()}>
          <Close />
        </button>
      </div>
      <RecurringDepositsFormWrapped {...props} />
    </>
  );
};
