import React from "react";
import { Skeleton } from "@mui/material";

export default function SkeletonLoader({
    width = "100%",
    height = 24,
    variant = "rectangular",
    className = "",
    sx = {
        bgcolor: "var(--loader-bg-light-secondary)"
    },
}) {
    const defaultBgColor = {
        backgroundColor: "var(--loader-bg-light-secondary)",
    };
    return (
        <Skeleton
            className={className}
            variant={variant}
            width={width}
            height={height}
            sx={{ ...defaultBgColor, ...sx }}
        />
    );
}
