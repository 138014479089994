import { useMemo } from "react";
import { parseAbsolute } from "@internationalized/date";

import Calendar from "../../Components/Calendar";

import renderRentMatch from "../../Organisms/Modals/Renting/rent-match";

import { useModalContext } from "../../Contexts/ModalContext";

import { formatDate, getNextThirdTuesday, isThirdTuesdayOfMonth } from "../../Utils/helpers";
import { nilYearLongMonthDateFormat } from "../../Utils/constants";

const nextThirdTuesday = getNextThirdTuesday();

export default function RentCalendar({ rentMatch }) {
  const modalContext = useModalContext();

  const calendarDefaultValue = useMemo(() => {
    const dayBeforeNextThirdTuesday = new Date(nextThirdTuesday);
    dayBeforeNextThirdTuesday.setDate(dayBeforeNextThirdTuesday.getDate() + 1);
    return parseAbsolute(dayBeforeNextThirdTuesday.toISOString());
  }, []);
  const calendarDefaultFocusedValue = useMemo(() => parseAbsolute(nextThirdTuesday.toISOString()), []);

  const handleRentCalendarSelect = (d) => {
    if (isThirdTuesdayOfMonth(d.day, d.month - 1, d.year, true)) {
      renderRentMatch(modalContext, rentMatch, formatDate(nextThirdTuesday, nilYearLongMonthDateFormat));
    }
  };

  return (
    <div className="rent-calendar">
      <Calendar
        value={calendarDefaultValue}
        onChange={handleRentCalendarSelect}
        defaultFocusedValue={calendarDefaultFocusedValue}
      />
      <div className="calendar-legend mt-6 mb-3 mx-2">
        <div className="legend-item green">
          <span className="legend-badge"></span>Rent Match
        </div>
      </div>
    </div>
  );
}
