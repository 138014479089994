import { useEffect } from "react";

import { isDevReactEnv } from "./constants";

function initialiseUXCam(appKey, opts) {
  window.uxc = {
    __t: [],
    __ak: appKey,
    __o: opts,
    event: function (n, p) {
      this.__t.push(["event", n, p]);
    },
    setUserIdentity: function (i) {
      this.__t.push(["setUserIdentity", i]);
    },
    setUserProperty: function (k, v) {
      this.__t.push(["setUserProperty", k, v]);
    },
    setUserProperties: function (p) {
      this.__t.push(["setUserProperties", p]);
    },
  };

  // Create and inject the script tag into the <head>
  const head = document.getElementsByTagName("head")[0];
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.src = "//websdk-recording.uxcam.com/index.js";
  script.async = true;
  script.defer = true;
  script.id = "uxcam-web-sdk";
  script.crossOrigin = "anonymous";
  head.appendChild(script);
}

export default function UXCamLib() {
  useEffect(() => {
    // Check if the script is already injected to avoid duplicate scripts
    if (!isDevReactEnv && !document.getElementById("uxcam-web-sdk")) {
      // Initialize UXCam configuration
      initialiseUXCam(process.env.REACT_APP_UXCAM_APP_KEY, {});
    }
  }, []);

  return <></>;
}
