import { RentDetails as RentDetailsIllustration } from "../../Assets/Images";

import { formatCurrency } from "../../Utils/helpers";

export default function RentDetails({ address, price}) {
  return (
    <div className="rent-details">
      <div className="content">
        <div className="detail">
          <span className="detail-name">My Home</span>
          <span className="detail-value">{address}</span>
        </div>
        <div className="detail">
          <span className="detail-name">Rent price</span>
          <span className="detail-value">{formatCurrency(price)}</span>
        </div>
      </div>
      <img className="illustration" src={RentDetailsIllustration} alt="" />
    </div>
  );
}
