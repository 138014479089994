import React from "react";
import { OutlinedInput } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { IMaskInput } from "react-imask";

import { validation } from "../../Utils/validations";

import { phoneNumberMask } from "../../Utils/constants";

import { removeControlCharacters } from "../../Utils/helpers";

export default function UserNameField() {
  const { register } = useFormContext();

  const userNameFieldProps = register("username", {
    required: "Email / phone number is required",
    validate: (username) => {
      return (
        validation.email.isAValidEmail(username) ||
        validation.number.isUSPhoneNumber(username) ||
        "Please check that you've entered your email address or phone number correctly"
      );
    },
  });

  return (
    <OutlinedInput
      {...userNameFieldProps}
      inputRef={userNameFieldProps.ref}
      inputComponent={UserNameFieldMaskAdapter}
      autoFocus
    />
  );
}
const UserNameFieldMaskAdapter = React.forwardRef((props, ref) => {
  const { onChange, onBlur, ...other } = props;

  return (
    <IMaskInput
      {...other}
      mask={[
        {
          mask: phoneNumberMask,
          eager: true,
        },
        {
          mask: String,
        },
      ]}
      inputRef={ref}
      onAccept={(value) => onChange?.({ target: { name: "username", value: removeControlCharacters(value) } })}
      onBlur={(e) => onBlur({ target: { name: "username", value: removeControlCharacters(e.target.value) } })}
    />
  );
});
