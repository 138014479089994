import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Divider } from "@mui/material";

import renderNoPaymentEntityFound from "../../Organisms/Modals/Banking/no-payment-entity-found";

import { useModalContext } from "../../Contexts/ModalContext";

import { getAstraCardsWithSystemCardFiltered, isSVDApproved } from "../../Utils/helpers";

export default function BankingActionButtons({ stakeDebit = {}, connectedAccounts = {}, astraDebitCards = {} }) {
  const navigate = useNavigate();

  const modalContext = useModalContext();

  const isBankAccountConnected = useMemo(() => {
    return connectedAccounts?.data?.data?.accounts?.length > 0;
  }, [connectedAccounts?.data?.data?.accounts]);

  const isAstraAccountConnected = useMemo(() => {
    return getAstraCardsWithSystemCardFiltered(astraDebitCards, stakeDebit).length > 0;
  }, [astraDebitCards, stakeDebit]);

  const IsSVDApproved = useMemo(() => {
    return isSVDApproved(stakeDebit?.data);
  }, [stakeDebit?.data]);

  if (!IsSVDApproved) {
    return <></>;
  }

  const handleOnActionButtonClick = (pathname, transferType) => {
    if (!isBankAccountConnected && !isAstraAccountConnected) {
      renderNoPaymentEntityFound(modalContext, transferType);
    } else {
      navigate(`/banking/${pathname}`);
    }
  };

  return (
    <div className="banking-action-buttons">
      <button className="add-cash-button" onClick={handleOnActionButtonClick.bind(this, "add-cash", "add cash")}>
        Add Cash
      </button>
      <Divider orientation="vertical" flexItem />
      <button className="cash-out-button" onClick={handleOnActionButtonClick.bind(this, "cash-out", "cash out")}>
        Cash Out
      </button>
    </div>
  );
}
