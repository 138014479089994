import PrimaryButton from "../../../Components/Buttons/primary-button";

import { useModalContext } from "../../../Contexts/ModalContext";
import { useViewport } from "../../../Contexts/ViewportContext";

import { mediumScreenStartFrom, modalVariants } from "../../../Utils/constants";

export default function renderActivateCard(modalContext) {
  const { updateModalComponent, setModalVariant, setModalTitle } = modalContext;

  updateModalComponent(() => <ActivateCard />);
  setModalVariant(modalVariants.infoDialog);
  setModalTitle("Activate your card");
}

const ActivateCard = () => {
  const modalContext = useModalContext();
  const { onModalClose } = modalContext;

  const { width } = useViewport();

  return (
    <>
      <span className="modal-content-text mt-2 mb-6">
        Once your card arrives, activate it by calling Visa at +1 (833) 333-0417.
      </span>
      <div className="action-container">
        {width <= mediumScreenStartFrom && (
          <a className="primary-button blue-theme text-decoration-none" href="tel:+18333330417" onClick={onModalClose}>
            Call +1 (833) 333-0417
          </a>
        )}
        <PrimaryButton className="secondary-colors" onClick={onModalClose}>
          Dismiss
        </PrimaryButton>
      </div>
    </>
  );
};
