import { createContext, useContext, useEffect, useState } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";
import { CircularProgress } from "@mui/material";

import usePrefetchOnLogin from "../CustomHooks/usePrefetchOnLogin";

import { APIQueryKeys, appUtilityKeys } from "../Utils/constants";

import {
  dispatchErrorToast,
  getHasVisitedWelcomeScreenKey,
  LocalStorage,
  saveAndUpdateUserOnLogin,
} from "../Utils/helpers";

const RedirectContext = createContext();

export const RedirectContextProvider = ({ children }) => {
  const [authKey, setAuthKey] = useState(LocalStorage.read(appUtilityKeys.authKey));
  const [hasVisitedWelcomeScreen, setHasVisitedWelcomeScreen] = useState(
    LocalStorage.read(getHasVisitedWelcomeScreenKey())
  );

  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();

  const {
    isLoading: isPreloadingData,
    isSuccess: isPreloadedDataFetchSuccess,
    isError: isPreloadedError,
    hasUnclaimedTransactionsData,
  } = usePrefetchOnLogin({ authKey, hasVisitedWelcomeScreen });

  const onLogin = ({ data }) => {
    saveAndUpdateUserOnLogin(data);
    queryClient.resetQueries();
    setHasVisitedWelcomeScreen(LocalStorage.read(getHasVisitedWelcomeScreenKey()));
    setAuthKey(data?.data?.[appUtilityKeys.authKey]);
  };

  const markVisitedWelcomePage = () => {
    LocalStorage.write(getHasVisitedWelcomeScreenKey(), true);
    setHasVisitedWelcomeScreen(true);
  };

  useEffect(() => {
    if (!!authKey) {
      queryClient.refetchQueries(APIQueryKeys.deals);
      queryClient.refetchQueries(APIQueryKeys.userAccount);
      queryClient.refetchQueries(APIQueryKeys.transferToken);
      if (!hasVisitedWelcomeScreen) {
        queryClient.refetchQueries(APIQueryKeys.mobileAppSummary);
        queryClient.refetchQueries(APIQueryKeys.platformSummaryStats);
      }
    }
  }, [queryClient, authKey, hasVisitedWelcomeScreen]);

  useEffect(() => {
    if (!isPreloadingData && isPreloadedDataFetchSuccess) {
      if (!hasVisitedWelcomeScreen) {
        navigate("/welcome");
      } else if (hasUnclaimedTransactionsData) {
        navigate("/unclaimed");
      } else {
        navigate("/banking");
      }
    } else if (!isPreloadingData && isPreloadedError) {
      dispatchErrorToast("Something has gone wrong. Please contact support.", { key: "redirectError" });
    }
  }, [
    hasVisitedWelcomeScreen,
    isPreloadingData,
    isPreloadedDataFetchSuccess,
    isPreloadedError,
    hasUnclaimedTransactionsData,
    navigate,
  ]);

  if (!authKey && location.pathname !== "/login") {
    return <Navigate to="/login" />;
  }

  return (
    <RedirectContext.Provider value={{ onLogin, markVisitedWelcomePage }}>
      {isPreloadingData ? (
        <div className="onboarding-page justify-content-center">
          <CircularProgress sx={{ color: "var(--text-primary)" }} />
        </div>
      ) : !!children ? (
        children
      ) : (
        <Outlet />
      )}
    </RedirectContext.Provider>
  );
};

export const useRedirectContext = () => {
  const modalContextData = useContext(RedirectContext);
  if (modalContextData === undefined) {
    throw new Error("useRedirectContext must be used within a RedirectContextProvider");
  }
  return modalContextData;
};
