import { useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";

import { AlertConfirmationModal } from "../alert-confirmation-modal";
import renderStatusModal from "../status-modal";
import renderClearRecurringDepositFailed from "./clear-recurring-deposit-failed";

import { useClearRecurringDeposit } from "../../../CustomHooks/Mutations";

import { useModalContext } from "../../../Contexts/ModalContext";

import {
  APIQueryKeys,
  ClearRecurringDepositFailureStatusModalProps,
  modalVariants,
  SuccessClearRecurringDepositStatusModalProps,
} from "../../../Utils/constants";

export default function renderConfirmClearRecurringDeposit(modalContext) {
  const { updateModalComponent, setModalVariant } = modalContext;

  updateModalComponent(() => <ConfirmClearRecurringDeposit />);
  setModalVariant(modalVariants.alertConfirmationDialog);
}

function ConfirmClearRecurringDeposit() {
  const navigate = useNavigate();

  const modalContext = useModalContext();

  const { onModalClose } = modalContext;

  const queryClient = useQueryClient();

  const { isLoading: isClearingRecurringDeposit, mutate: ClearRecurringDeposit } = useClearRecurringDeposit({
    onSuccess: () => {
      queryClient.refetchQueries(APIQueryKeys.recurringDeposit);
      renderStatusModal(modalContext, {
        ...SuccessClearRecurringDepositStatusModalProps,
        onCTAButtonClick: () => onModalClose(),
        onSecondaryCTAButtonClick: () => {
          navigate("/banking");
          onModalClose();
        },
      });
    },
    onError: () =>
      renderClearRecurringDepositFailed(modalContext, {
        ...ClearRecurringDepositFailureStatusModalProps,
        onCTAButtonClick: () => ClearRecurringDeposit(),
        onSecondaryCTAButtonClick: () => onModalClose(),
      }),
  });

  return (
    <AlertConfirmationModal
      variant="alert-neutral"
      title="Changes take up to 1 business day to take effect"
      description="Any transfers that have already been initiated will arrive in your account on the next business day."
      CTAButtonText="Continue"
      isCTALoading={isClearingRecurringDeposit}
      onCTAButtonClick={() => ClearRecurringDeposit()}
      secondaryCTAButtonText="Cancel"
      onSecondaryCTAButtonClick={() => onModalClose()}
    />
  );
}
