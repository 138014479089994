import SkeletonLoader from "../../Components/Skeleton/skeleton-loader";

import { Stack } from "@mui/material";

export default function PersonalInformationSkeleton() {
  return (
    <div className="form-card">
      <Stack className="gap-6" direction="column" justifyContent="flex-start">
        {Array.from(Array(8).keys()).map((_, idx) => (
          <Stack key={idx} className="gap-3">
            <SkeletonLoader className="border--rounded-sm" width="100%" height={24} sx={{ maxWidth: "80px" }} />
            <SkeletonLoader className="border--rounded-sm" width="100%" height={48} sx={{ maxWidth: "100%" }} />
          </Stack>
        ))}
      </Stack>
    </div>
  );
}
