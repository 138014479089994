// import { useQueryClient } from "react-query";
// import { CircularProgress } from "@mui/material";

import { useIntercom } from "react-use-intercom";
import PrimaryButton from "../../../Components/Buttons/primary-button";

import { useModalContext } from "../../../Contexts/ModalContext";
// import { useDisconnectPayrollAccount } from "../../../CustomHooks/Mutations";

// import { dispatchAPIErrorToast } from "../../../Utils/helpers";
import {
  // APIQueryKeys,
  modalVariants,
} from "../../../Utils/constants";

export default function renderRemovePayroll(modalContext, account) {
  const { updateModalComponent, setModalVariant, setModalTitle } = modalContext;

  updateModalComponent(() => <RemovePayroll account={account} />);
  setModalVariant(modalVariants.infoDialog);
  setModalTitle("");
}

const RemovePayroll = ({ account }) => {
  const { onModalClose } = useModalContext();

  const { show: showIntercom } = useIntercom();

  // const queryClient = useQueryClient();

  // const { isLoading: isGettingUpdatePayrollAccountToken, mutate: DisconnectPayrollAccount } =
  //   useDisconnectPayrollAccount({
  //     onSuccess: () => {
  //       queryClient.refetchQueries(APIQueryKeys.payrollAccount);
  //       onModalClose();
  //     },
  //     onError: () => dispatchAPIErrorToast(),
  //   });

  return (
    <>
      <img className="modal-status-icon border--rounded-full mb-sm-4 mb-2" src={account?.logo_url} alt="" />
      <h3 className="modal-status-title mb-2">Remove Transfer</h3>
      <span className="modal-content-text mb-sm-6 mb-4">
        To remove, we will set the transfer amount to $0. Please tap continue.
      </span>
      <span className="modal-content-text mb-sm-6 mb-4">
        To permanently delete the connection, please contact your payroll provider.
      </span>
      <div className="action-container">
        <PrimaryButton
          className="blue-theme"
          onClick={() => {
            showIntercom();
            onModalClose();
          }}
          // onClick={DisconnectPayrollAccount.bind(this, account.account_id)}
          // disabled={isGettingUpdatePayrollAccountToken}
        >
          Contact Support
          {/* Continue {isGettingUpdatePayrollAccountToken && <CircularProgress />} */}
        </PrimaryButton>
        <PrimaryButton className="secondary-colors" onClick={onModalClose}>
          Never Mind
        </PrimaryButton>
      </div>
    </>
  );
};
