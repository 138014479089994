import PropTypes from "prop-types";
import React from "react";
import { TextField } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { IMaskInput } from "react-imask";

export default function AmountField({ rules = {} }) {
  const { control } = useFormContext();

  return <Controller name="cash-amount" control={control} rules={rules} render={ControlledAmountField} />;
}

const ControlledAmountField = ({ field }) => {
  return (
    <div className="amount-field">
      <TextField
        {...field}
        placeholder="$0.00"
        autoFocus
        InputProps={{
          inputComponent: AmountFieldMaskAdapter,
        }}
      />
    </div>
  );
};

const AmountFieldMaskAdapter = React.forwardRef((props, ref) => {
  const { onChange, value, max, ...other } = props;

  return (
    <IMaskInput
      {...other}
      value={value?.masked}
      mask="$num"
      blocks={{
        num: {
          mask: Number,
          scale: 2,
          thousandsSeparator: ",",
          padFractionalZeros: true,
          normalizeZeros: true,
          radix: ".",
          min: 0,
          max: max ?? 1000000,
        },
      }}
      inputRef={ref}
      onAccept={(value, maskRef) => onChange?.({ masked: value, unmasked: maskRef.unmaskedValue })}
    />
  );
});

AmountField.prototype = { rules: PropTypes.object };
