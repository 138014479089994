import PropTypes from "prop-types";
import React, { useRef } from "react";
import { useCalendar, useCalendarGrid, useCalendarCell } from "@react-aria/calendar";
import { useLocale } from "@react-aria/i18n";
import { getWeeksInMonth, createCalendar } from "@internationalized/date";
import { useCalendarState } from "@react-stately/calendar";

import { isThirdTuesdayOfMonth } from "../../Utils/helpers";

import { ArrowLeft, ArrowRight } from "../../Assets/Icons";

export default function Calendar({ ...props }) {
  let { locale } = useLocale();
  let state = useCalendarState({
    ...props,
    locale,
    createCalendar,
  });

  let { calendarProps, prevButtonProps, nextButtonProps, title } = useCalendar(props, state);

  return (
    <div {...calendarProps} className="custom-calendar">
      <div className="header">
        <button
          disabled={prevButtonProps.isDisabled}
          onClick={prevButtonProps.onPress}
          aria-label={prevButtonProps["aria-label"]}
          onBlur={prevButtonProps.onFocusChange}
        >
          <ArrowLeft />
        </button>
        <h2>{title}</h2>
        <button
          disabled={nextButtonProps.isDisabled}
          onClick={nextButtonProps.onPress}
          aria-label={nextButtonProps["aria-label"]}
          onBlur={nextButtonProps.onFocusChange}
        >
          <ArrowRight />
        </button>
      </div>
      <CalendarGrid state={state} weekdayStyle="short" />
    </div>
  );
}

function CalendarGrid({ state, ...props }) {
  let { locale } = useLocale();
  let { gridProps, headerProps, weekDays } = useCalendarGrid(props, state);

  let weeksInMonth = getWeeksInMonth(state.visibleRange.start, locale);

  return (
    <div className="month-calendar" {...gridProps}>
      <div className="weekdays" {...headerProps}>
        {weekDays.map((day, index) => (
          <div key={index} className="weekday">
            {day}
          </div>
        ))}
      </div>
      <div className="month-dates">
        {[...new Array(weeksInMonth).keys()].map((weekIndex) => (
          <div key={weekIndex} className="month-week">
            {state
              .getDatesInWeek(weekIndex)
              .map((date, i) =>
                date ? (
                  <CalendarCell
                    key={i}
                    state={state}
                    date={date}
                    isThirdTuesday={isThirdTuesdayOfMonth(date.day, date.month - 1, date.year)}
                  />
                ) : (
                  <React.Fragment key={i} />
                )
              )}
          </div>
        ))}
      </div>
    </div>
  );
}

function CalendarCell({ state, date, isThirdTuesday }) {
  let ref = useRef(null);
  let { cellProps, buttonProps, isOutsideVisibleRange, isSelected, isDisabled, formattedDate } = useCalendarCell(
    { date },
    state,
    ref
  );

  return (
    <div
      {...cellProps}
      className={`date${isSelected ? " selected" : ""}${isDisabled || isOutsideVisibleRange ? " disabled" : ""}${
        isThirdTuesday ? " badge green" : ""
      }`}
    >
      <div {...buttonProps} ref={ref}>
        {formattedDate}
      </div>
    </div>
  );
}

Calendar.prototype = { shouldMarkDate: PropTypes.func };
