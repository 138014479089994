import { Dialog } from "@mui/material";
import React, { createContext, useContext, useRef, useState } from "react";

import { modalVariants } from "../Utils/constants";

import { ArrowLeftCircle, Close } from "../Assets/Icons";

const ModalContext = createContext();

export const ModalContextProvider = ({ children }) => {
  const [modalVisibility, setModalVisibility] = useState(false);
  const [modalClassName, setModalClassName] = useState(null);
  const [ModalComponent, setModalComponent] = useState(() => React.Fragment);

  const [modalVariant, setModalVariant] = useState(modalVariants.infoDialog);
  const [modalTitle, setModalTitle] = useState("");
  const [modalStep, setModalStep] = useState(1);
  const [ModalPromoComponent, setModalPromoComponent] = useState(null);
  const [modalOnCloseCallBack, setModalOnCloseCallBack] = useState(null);
  const [modalProps, setModalProps] = useState({});

  const removeComponentTimeoutRef = useRef(null);

  const updateModalComponent = (Component, props = {}) => {
    clearTimeout(removeComponentTimeoutRef?.current);
    setModalComponent(() => Component);
    setModalProps(props);
    setModalVisibility(true);
  };

  const removeModalComponent = () => {
    setModalComponent(() => () => React.Fragment);
  };

  const updateModalOnCloseCallBack = (callback) => setModalOnCloseCallBack(() => callback);

  const onModalClose = ({ abortCallingOnCloseCallback = false } = {}) => {
    if (!!modalOnCloseCallBack && !abortCallingOnCloseCallback) {
      modalOnCloseCallBack();
    }
    setModalVisibility(false);

    removeComponentTimeoutRef.current = setTimeout(() => {
      removeModalComponent();
      setModalClassName(null);
      setModalVariant(modalVariants.infoDialog);
      setModalTitle("");
      setModalStep(1);
      setModalPromoComponent(null);
      setModalOnCloseCallBack(null);
      setModalProps({});
    }, 250);
  };

  const onBackButtonClick = () => setModalStep((step) => step - 1);

  return (
    <ModalContext.Provider
      value={{
        modalVisibility,
        setModalVisibility,
        modalClassName,
        setModalClassName,
        modalVariant,
        setModalVariant,
        modalTitle,
        setModalTitle,
        ModalComponent,
        updateModalComponent,
        removeModalComponent,
        onModalClose,
        modalStep,
        setModalStep,
        ModalPromoComponent,
        setModalPromoComponent,
        modalOnCloseCallBack,
        updateModalOnCloseCallBack,
        modalProps,
      }}
    >
      {children}
      <Dialog
        className={`app-utility-modal ${modalClassName ?? ""} ${modalVariant}`}
        open={modalVisibility}
        onClose={onModalClose}
      >
        {!!ModalPromoComponent && (
          <div className="modal-promo-section">
            <ModalPromoComponent />
          </div>
        )}
        <div className="modal-dialog-header">
          {modalStep !== 1 && <ArrowLeftCircle className="go-back-button" onClick={onBackButtonClick} />}
          <h3 className="title">{modalTitle}</h3>
          <button className="close-button" onClick={onModalClose}>
            <Close />
          </button>
        </div>
        <ModalComponent {...modalProps} />
      </Dialog>
    </ModalContext.Provider>
  );
};

export const useModalContext = () => {
  const modalContextData = useContext(ModalContext);
  if (modalContextData === undefined) {
    throw new Error("useModalConntext must be used within a ModalContextProvider");
  }
  return modalContextData;
};
