import PropTypes from "prop-types";

export default function StepIndicator({ className = "", steps = 0, activeStep }) {
  return (
    <div className={`step-indicator ${className}`}>
      {Array.from(Array(steps).keys()).map((step, idx) => (
        <div key={idx} className={`step ${step + 1 === activeStep ? "active" : ""}`}></div>
      ))}
    </div>
  );
}

StepIndicator.prototype = { className: PropTypes.string, steps: PropTypes.number, activeStep: PropTypes.number };
