import { useEffect, useRef, useState } from "react";

export default function useDetectSticky(stickyPosition, observerSettings = {}) {
  const [isSticky, setIsSticky] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const cachedRef = ref.current,
      observer = new IntersectionObserver(
        (entries) =>
          entries.forEach((entry) => {
            const BCRect = entry.boundingClientRect[stickyPosition];
            const IRect = entry.intersectionRect[stickyPosition];
            setIsSticky(
              entry.intersectionRatio < 1 && ["top", "left"].includes(stickyPosition) ? BCRect < IRect : BCRect > IRect
            );
          }),
        { threshold: [1], ...observerSettings }
      );

    observer.observe(cachedRef);

    return () => observer.unobserve(cachedRef);
  }, [stickyPosition, observerSettings]);

  return [isSticky, ref, setIsSticky];
}
