import { useMemo } from "react";

import ModalSkeleton from "../../../../Components/Skeleton/modal-skeleton";
import { StickyRentTrackerItem } from "../../../../Components/Others/rent-tracker-item";

import { useCreditReporting, usePaymentHistories } from "../../../../CustomHooks/Queries";

import { modalVariants } from "../../../../Utils/constants";

import { sortedAndGroupedPayments } from "../../../../Utils/api-data-transforms";

import "./rent-tracker.scss";

export default function renderRentTracker(modalContext) {
  const { updateModalComponent, setModalClassName, setModalVariant, setModalTitle } = modalContext;

  updateModalComponent(RentTracker);
  setModalVariant(modalVariants.infoDialog);
  setModalClassName("rent-tracker-dialog");
  setModalTitle("Rent Tracker");
}

const RentTracker = () => {
  const { isLoading: isLoadingPaymentHistory, data: paymentHistory } = usePaymentHistories();
  const { isLoading: isLoadingCreditReporting, data: creditReporting } = useCreditReporting();

  const groupedPayments = useMemo(() => sortedAndGroupedPayments(paymentHistory?.data?.data, true), [paymentHistory]);

  const isLoading = isLoadingPaymentHistory || isLoadingCreditReporting;

  return isLoading ? (
    <ModalSkeleton />
  ) : (
    <div id="rent-tracker-items">
      {groupedPayments.map(({ year, payments }, i) => (
        <StickyRentTrackerItem
          key={i}
          styled={false}
          paymentList={payments}
          showReporting={creditReporting?.data?.data?.opted_in}
          year={year}
          groupedByYear={true}
        />
      ))}
    </div>
  );
};
