import { useState } from "react";

import SplashScreen from "./Splash";
import TrustScreen from "./Trust";

import { useRedirectContext } from "../../Contexts/RedirectContext";

export default function Welcome() {
  const [showSplash, setShowSplash] = useState(true);

  const { markVisitedWelcomePage } = useRedirectContext();

  return showSplash ? (
    <SplashScreen onCTATrigger={setShowSplash.bind(this, false)} />
  ) : (
    <TrustScreen onCTATrigger={markVisitedWelcomePage.bind(this)} />
  );
}
