import { useMemo } from "react";
import { Divider, Stack } from "@mui/material";

import PropTypes from "prop-types";

import renderActivityList from "../../Organisms/Modals/Banking/Activity List/activity-list";

import { useModalContext } from "../../Contexts/ModalContext";

import { formatCurrency } from "../../Utils/helpers";

import { sortActivitiesByDate, getActivityDateOrStatus } from "../../Utils/api-data-transforms";

export default function BankingActivity({ bankActivityList = [] }) {
  const modalContext = useModalContext();

  // Sort the activities by date (newest first)
  const sortedActivities = useMemo(
    () => sortActivitiesByDate(bankActivityList?.data?.data?.activities ?? []),
    [bankActivityList?.data?.data?.activities]
  );

  return (
    <div className="banking-activity">
      <div className="section-header">
        <h3 className="section-title">Activity</h3>
        {sortedActivities.length > 3 && (
          <button className="see-all-button" onClick={renderActivityList.bind(this, modalContext, sortedActivities)}>
            See All
          </button>
        )}
      </div>
      {sortedActivities.length === 0 ? (
        <div className="activity-list no-records">No activity yet</div>
      ) : (
        <Stack className="activity-list" direction="column" divider={<Divider className="activity-divider" />}>
          {sortedActivities.slice(0, 3).map((activity) => (
            <div key={activity._id} className="activity-item">
              <Stack className="row-1" direction="row" alignItems="center" justifyContent="space-between">
                <span className="activity-date">{getActivityDateOrStatus(activity)}</span>
                <span className="activity-amount">
                  {activity.direction === "Credit" ? "+" : "-"}
                  {formatCurrency(Math.abs(activity.amount))}
                </span>
              </Stack>
              <Stack className="row-2" direction="row" alignItems="center" justifyContent="space-between">
                <span className="activity-name">{activity.category}</span>
                <span className="balance-after-activity">{formatCurrency(activity.balance)}</span>
              </Stack>
            </div>
          ))}
        </Stack>
      )}
    </div>
  );
}

BankingActivity.prototype = {
  loading: PropTypes.bool,
};
