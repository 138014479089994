import PrimaryButton from "../../Components/Buttons/primary-button";

import renderCreditBuilderOptIn from "../../Organisms/Modals/Credit-Builder/credit-builder-opt-in";
import renderCreditBuilderOptOut from "../../Organisms/Modals/Credit-Builder/credit-builder-opt-out";

import { useModalContext } from "../../Contexts/ModalContext";

import { CreditBuilderCoin, CreditBuilderSparkle } from "../../Assets/Images";

export default function CreditBuilder({ optedIn }) {
  const modalContext = useModalContext();

  const handleEditPreference = () => {
    if (optedIn) {
      renderCreditBuilderOptOut(modalContext);
    } else {
      renderCreditBuilderOptIn(modalContext);
    }
  };
  return (
    <div className="credit-builder mt-sm-6 mt-5">
      <img className="coin" src={CreditBuilderCoin} alt=""></img>
      <img className="sparkle" src={CreditBuilderSparkle} alt=""></img>
      <h3 className="title mb-sm-2 mb-1">Credit Builder</h3>
      <span className="description mb-sm-8 mb-6">
        Stake only reports on-time rent payments that help build your credit. Learn more.
      </span>
      <PrimaryButton className="blue-theme" onClick={handleEditPreference}>
        {optedIn ? "Edit my preferences" : "Start"}
      </PrimaryButton>
    </div>
  );
}
