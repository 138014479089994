import PrimaryButton from "../../../Components/Buttons/primary-button";

import { modalVariants } from "../../../Utils/constants";

import { SVD_Single_Card } from "../../../Assets/Images";

export default function renderVirtualSVDApproved(modalContext, onPrimaryButtonClick) {
  const { updateModalComponent, setModalVariant, setModalPromoComponent, setModalTitle } = modalContext;

  updateModalComponent(() => {
    return (
      <>
        <img className="modal-image" src={SVD_Single_Card} alt="" />
        <h3 className="modal-status-title mt-6 mb-0">You’ve been approved!</h3>
        <span className="modal-content-text highlight mt-2">
          Your virtual Stake Visa Debit card is now available for use
        </span>
        <span className="text-lg-regular color--medium-grey text-center white-space-pre-line mt-3">
          You can request a physical card from the home screen if you’d like.{"\n\n"}You can view your virtual card
          number and security code from the home screen.
        </span>
        <div className="action-container mt-6">
          <PrimaryButton onClick={onPrimaryButtonClick}>Continue</PrimaryButton>
        </div>
      </>
    );
  });
  setModalPromoComponent(null);
  setModalTitle("");
  setModalVariant(modalVariants.stepByStepDialog);
}
