import { useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { CircularProgress } from "@mui/material";
import { closeSnackbar } from "notistack";
import { useQueryClient } from "react-query";

import PrimaryButton from "../../../Components/Buttons/primary-button";
import TextField from "../../../Components/Form Fields/text-field";
import OTPField from "../../../Components/Form Fields/otp-field";

import withFormProvider from "../../../HOC/withFormProvider";

import { useModalContext } from "../../../Contexts/ModalContext";
import { useAppUtilityContext } from "../../../Contexts/AppUtilityContext";

import {
  useEmailChangeRequestVerification,
  useEmailChangeVerification,
  useUpdateEmailAddress,
} from "../../../CustomHooks/Mutations";

import { validation } from "../../../Utils/validations";

import { APIQueryKeys, modalVariants } from "../../../Utils/constants";

import { dispatchErrorToast, dispatchInfoToast, dispatchSuccessToast } from "../../../Utils/helpers";

export default function renderEditEmail(modalContext, email, sfid) {
  const { updateModalComponent, setModalVariant, setModalTitle } = modalContext;

  updateModalComponent(withFormProvider(EditEmail, { defaultValues: { email, sfid } }));
  setModalVariant(modalVariants.formDialog);
  setModalTitle("Enter new email");
}

const EditEmail = () => {
  const resendOtpRef = useRef(false);

  const { register, handleSubmit, formState, watch, getFieldState } = useFormContext();

  const [sentVerificationEmail, setSentVerificationEmail] = useState(false);

  const { setModalTitle, onModalClose, updateModalOnCloseCallBack } = useModalContext();

  const { onEmailOrPhoneNumbeChange } = useAppUtilityContext();

  const queryClient = useQueryClient();

  //Setting Dialog title on dependancy change
  useEffect(() => {
    if (!sentVerificationEmail) {
      setModalTitle("Enter new email");
    } else {
      setModalTitle("Verify your new email");
    }
  }, [sentVerificationEmail, setModalTitle]);

  const email = watch("email");
  const otp = watch("otp");

  const { isLoading: isRequestingEmailChangeVerification, mutate: RequestEmailChangeVerification } =
    useEmailChangeRequestVerification({
      onSuccess: () => {
        setSentVerificationEmail(true);
        if (resendOtpRef.current) {
          resendOtpRef.current = false;
          dispatchSuccessToast(`New verification code sent to ${email}`, { key: "requestEmailChange" });
        } else {
          dispatchSuccessToast(`Verification code sent to ${email}`);
          updateModalOnCloseCallBack(() =>
            dispatchInfoToast("Changes not saved", {
              key: "requestEmailChange",
              alignToAppContent: true,
            })
          );
        }
      },
      onError: (err) => {
        dispatchErrorToast(err?.response?.data?.error?.msg, { key: "requestEmailChange" });
        if (resendOtpRef.current) {
          resendOtpRef.current = false;
        }
      },
    });

  const { isLoading: isChangingEmailVerification, mutate: ChangeEmailVerification } = useEmailChangeVerification({
    onSuccess: (data) => {
      onEmailOrPhoneNumbeChange(data);
      queryClient.refetchQueries(APIQueryKeys.userAccount);
      UpdateEmail({ userId: formState.defaultValues.sfid, emailAddress: email });
    },
    onError: () =>
      dispatchErrorToast("Invalid verification code, please try again", {
        key: "changeEmail",
      }),
  });

  const { isLoading: isPatchingEmailAddress, mutate: UpdateEmail } = useUpdateEmailAddress({
    onSuccess: (data) => {
      dispatchSuccessToast("Email updated successfully", {
        key: "changeEmail",
        alignToAppContent: true,
      });
      onModalClose({ abortCallingOnCloseCallback: true });
    },
    onError: () =>
      dispatchErrorToast("Unable to update email address, please try again", {
        key: "changeEmail",
      }),
  });

  const isLoading = isRequestingEmailChangeVerification || isChangingEmailVerification || isPatchingEmailAddress;

  const onSubmit = () => {
    closeSnackbar();

    let payload = { email: email };

    if (!sentVerificationEmail || resendOtpRef.current) {
      RequestEmailChangeVerification(payload);
    } else {
      payload.phoneNumberCode = otp;
      ChangeEmailVerification(payload);
    }
  };

  const disableSubmitButton = !sentVerificationEmail ? !(email ?? "") || !getFieldState("email").isDirty : !(otp ?? "");

  const emailFieldProps = register("email", {
    required: true,
    validate: (email) => validation.email.isAValidEmail(email) || "Please enter a valid email address.",
  });

  const { error: emailFieldError } = getFieldState(emailFieldProps.name, formState);

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      {!sentVerificationEmail ? (
        <>
          <TextField className="my-7" {...emailFieldProps} autoFocus errorMessage={emailFieldError?.message} />
        </>
      ) : (
        <>
          <p className="modal-content-text mt-7 mb-4">
            Please enter the code we sent to <span className="username-badge">{email}</span>
          </p>
          <div className="otp-field light-theme">
            <OTPField />
          </div>
          <button
            className="d-block modal-content-text text-decoration-underline mt-4 mb-7 mx-auto"
            onClick={() => {
              resendOtpRef.current = true;
              onSubmit();
            }}
          >
            Didn’t receive an email? Resend code
          </button>
        </>
      )}
      <div className="action-container">
        <PrimaryButton className="blue-theme" type="submit" disabled={disableSubmitButton || isLoading}>
          Submit{isLoading && <CircularProgress />}
        </PrimaryButton>
        <PrimaryButton className="secondary-colors" type="button" onClick={onModalClose}>
          Discard Changes
        </PrimaryButton>
      </div>
    </form>
  );
};
