import PrimaryButton from "../../../../Components/Buttons/primary-button";

import { modalVariants } from "../../../../Utils/constants";

import { ReferAFriendModalImage } from "../../../../Assets/Images";

import "./opted-in.scss";

export default function renderOptedIn(modalContext) {
  const { updateModalComponent, setModalVariant, onModalClose } = modalContext;

  updateModalComponent(() => {
    return (
      <>
        <ReferAFriendModalImage className="coin-image mt-0 mb-sm-5 my-0 mx-auto" />
        <h3 className="modal-status-title mb-3">You’ve successfully enrolled in Stake Credit Builder</h3>
        <ul className="opted-in-benefits modal-content-text mb-0">
          <li>You will receive a confirmation text and a confirmation email.</li>
          <li>We will notify you every time we report one of your on time payments to help boost your credit score.</li>
          <li>
            We will only report your on-time payments. If you do not pay on time, we will not report anything to the
            credit bureaus.
          </li>
        </ul>
        <div className="action-container mt-6">
          <PrimaryButton className="blue-theme" onClick={onModalClose}>
            Continue
          </PrimaryButton>
        </div>
      </>
    );
  });
  setModalVariant(modalVariants.infoDialog);
}
