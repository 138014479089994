import { useMemo } from "react";
import { useIntercom } from "react-use-intercom";
import { CircularProgress } from "@mui/material";

import PrimaryButton from "../../../Components/Buttons/primary-button";
import ModalSkeleton from "../../../Components/Skeleton/modal-skeleton";

import renderRequestAddressUpdate from "./request_address_update";
import renderManageCard from "./manage-card";
import renderStatusModal from "../status-modal";

import { useStakeDebit } from "../../../CustomHooks/Queries";
import { useReplaceCard } from "../../../CustomHooks/Mutations";

import { useModalContext } from "../../../Contexts/ModalContext";

import { modalVariants } from "../../../Utils/constants";

import { CheckCircleOutline, Close } from "../../../Assets/Icons";

const SuccessfulTransferStatusModalProps = {
  StatusIcon: CheckCircleOutline,
  title: "Replacement Card Request\nSuccess",
  description: "You ordered a new card! We’ll send it to you ASAP",
  CTAButtonText: "Done",
};

const FailedTransferStatusModalProps = {
  StatusIcon: Close,
  statusClassName: "error",
  title: "Replacement Card Request\nFailed",
  description:
    "We're sorry, but your request for a replacement card could not be processed. Please try again later or contact support for assistance",
  CTAButtonText: "Try Again",
  secondaryCTAButtonText: "Contact Live Chat",
};

export default function renderConfirmAddressToReplaceCard(modalContext) {
  const { updateModalComponent, setModalVariant, setModalTitle } = modalContext;

  updateModalComponent(() => {
    const modalContext = useModalContext();

    const { show: showIntercom } = useIntercom();

    const { isLoading: isLoadingStakeDebit, data: stakeDebit } = useStakeDebit();

    const address = useMemo(() => stakeDebit?.data?.data?.address, [stakeDebit?.data?.data?.address]);

    const cityStateZipCode = useMemo(
      () => (!!address ? `${address?.city ?? ""}, ${address?.state ?? ""} ${address?.zipCode ?? ""}` : ""),
      [address]
    );

    const { isLoading: isReplacingCard, mutate: ReplaceCard } = useReplaceCard({
      onSuccess: () =>
        renderStatusModal(modalContext, {
          ...SuccessfulTransferStatusModalProps,
          onCTAButtonClick: renderManageCard.bind(this, modalContext),
        }),
      onError: () =>
        renderStatusModal(modalContext, {
          ...FailedTransferStatusModalProps,
          onCTAButtonClick: renderConfirmAddressToReplaceCard.bind(this, modalContext),
          onSecondaryCTAButtonClick: () => {
            showIntercom();
            modalContext.onModalClose();
          },
        }),
    });

    return isLoadingStakeDebit ? (
      <ModalSkeleton />
    ) : (
      <>
        <span className="d-block modal-content-text mt-5">{address?.street ?? ""}</span>
        {!!address?.street2 && address?.street2.length > 0 && (
          <span className="modal-content-text">{address?.street2}</span>
        )}
        <span className="modal-content-text">{cityStateZipCode}</span>
        <div className="action-container mt-5">
          <PrimaryButton
            className="blue-theme"
            onClick={ReplaceCard.bind(this, stakeDebit?.data?.data?.deposit_account_id)}
            disabled={isReplacingCard}
          >
            This is correct {isReplacingCard && <CircularProgress />}
          </PrimaryButton>
          <PrimaryButton className="secondary-colors" onClick={renderRequestAddressUpdate.bind(this, modalContext)}>
            Update Address
          </PrimaryButton>
        </div>
      </>
    );
  });
  setModalVariant(modalVariants.infoDialog);
  setModalTitle("First, please confirm your\naddress");
}
