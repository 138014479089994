import SkeletonLoader from "./skeleton-loader";
import { Stack } from "@mui/material";

export default function EarnMoreCardSkeleton() {
    return (
        <div className="earn-more-cards row skeleton">
            {Array.from(new Array(4)).map((_, idx) => (
                <div className="col-sm-6 col-12 px-sm-2 px-0" key={idx}>
                    <Stack className="earn-more-card py-6 gap-6"
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <div className="d-flex flex-column justify-content-between h-100 w-100">
                            <Stack className="gap-2">
                                <SkeletonLoader className="border--rounded-full title" width="100%" height={16} sx={{ maxWidth: '80px' }} />
                                <SkeletonLoader className="border--rounded-full description" width="100%" height={12} sx={{ maxWidth: '120px' }} />
                            </Stack>
                            <SkeletonLoader className="border--rounded-sm button" height={32} sx={{ maxWidth: '96px' }}/>
                        </div>
                        <SkeletonLoader className="border--rounded-sm image" width="100%" sx={{ maxWidth: '100px', minWidth: '100px', minHeight: '100px' }} />
                    </Stack>
                </div>
            ))}
        </div>
    );
}