import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

export default function UnclaimedListCard({ children }) {
  const unclaimedListRef = useRef(null);

  const [contentHeight, setContentHeight] = useState(0);

  useEffect(() => {
    setContentHeight(unclaimedListRef?.current?.offsetHeight ?? 0);
  }, [unclaimedListRef]);

  return (
    <div
      className="unclaimed-list-wrapper mt-3"
      style={{
        "--content-height": `${contentHeight}px`,
      }}
    >
      <div ref={unclaimedListRef} className="unclaimed-list-card">
        {children}
      </div>
    </div>
  );
}

UnclaimedListCard.prototype = { children: PropTypes.node };
