import PrimaryButton from "../../../Components/Buttons/primary-button";

import { modalVariants } from "../../../Utils/constants";
import { formatCurrency } from "../../../Utils/helpers";

export default function renderRentMatch(modalContext, rentMatch, nextThirdTuesday) {
  const { updateModalComponent, setModalVariant, setModalTitle, onModalClose } = modalContext;

  updateModalComponent(() => (
    <>
      <h1 className="modal-billboard-text my-sm-4 mt-3 mb-2">{formatCurrency(rentMatch)}</h1>
      <span className="modal-content-text mb-sm-6 mb-5">
        Stake gives you Cash Back for paying your rent on time. Your next rent match is {nextThirdTuesday}.
      </span>
      <div className="action-container">
        <PrimaryButton className="blue-theme" onClick={onModalClose}>
          Okay
        </PrimaryButton>
      </div>
    </>
  ));
  setModalVariant(modalVariants.infoDialog);
  setModalTitle("Rent Match");
}
