import PropTypes from "prop-types";
import { useEffect, useMemo, useRef } from "react";
import { Carousel } from "react-bootstrap";

import StarRating from "../../../Components/Others/star-rating";
import PrimaryButton from "../../../Components/Buttons/primary-button";
import ScrollToTop from "../../../Components/Others/scroll-to-top";

import { useMobileAppSummary, usePlatformSummaryStats } from "../../../CustomHooks/Queries";

import { mediumScreenStartFrom, reviewersData } from "../../../Utils/constants";

import { formatCurrency } from "../../../Utils/helpers";

import { StakeLogo } from "../../../Assets/Images";
import { ArrowLeftCircle, ArrowRightCircle } from "../../../Assets/Icons";

import "./trust.scss";
import "./trust-responsive.scss";

import { useViewport } from "../../../Contexts/ViewportContext";

export default function TrustScreen({ onCTATrigger }) {
  const { data: mobileAppSummaryAPIData } = useMobileAppSummary();

  const { data: platformSummaryStatsAPIData } = usePlatformSummaryStats();

  const mobileAppAverageUserRating = useMemo(
    () => parseFloat((mobileAppSummaryAPIData?.data?.data?.results?.[0]?.averageUserRating ?? 0).toFixed(1)),
    [mobileAppSummaryAPIData]
  );

  const totalCashBackPaid = useMemo(
    () => formatCurrency(platformSummaryStatsAPIData?.data?.data?.total_cash_back_paid ?? 0),
    [platformSummaryStatsAPIData]
  );

  const { width } = useViewport();
  const isMediumScreenStartFrom = width <= mediumScreenStartFrom;

  const buttonRef = useRef(null);

  useEffect(() => {
    const timeoutDuration = isMediumScreenStartFrom ? 4300 : 3000;
    const timer = setTimeout(() => {
      if (buttonRef.current) {
        const buttonRect = buttonRef.current.getBoundingClientRect();
        const scrollToY = window.scrollY + buttonRect.bottom - window.innerHeight;
        window.scrollTo({ top: scrollToY, behavior: "smooth" });
      }
    }, timeoutDuration);
  
    return () => clearTimeout(timer);
  }, [isMediumScreenStartFrom]);

  return (
    <div className="onboarding-page trust">
      <ScrollToTop />
      <header>
        <StakeLogo className="logo" />
      </header>
      <div className="max-width-container">
        <div className="trust-content">
          <div className="content-card animate-in-order order-1">
            <StarRating value={mobileAppAverageUserRating} />
            <h2 className="text-2xl-semibold color--text-primary my-1">{mobileAppAverageUserRating} stars</h2>
            <p className="text-xs-regular color--text-primary opaque-70 mb-0">
              Based on {mobileAppSummaryAPIData?.data?.results?.[0]?.userRatingCount} App Store reviews
            </p>
          </div>
          <div className={`row order-2 ${!isMediumScreenStartFrom ? "animate-in-order" : ""}`}>
            <div className="col-sm-6 col-12">
              <div className={`content-card ${isMediumScreenStartFrom ? "animate-in-order order-2" : ""}`}>
                <h2 className="text-2xl-semibold color--text-primary mb-1">
                  {platformSummaryStatsAPIData?.data?.data?.renters_earning_cash_back}
                </h2>
                <p className="text-xs-regular color--text-primary opaque-70 mb-0">Renters earning Cash Back</p>
              </div>
            </div>
            <div className="col-sm-6 col-12 mt-sm-0 mt-3">
              <div className={`content-card ${isMediumScreenStartFrom ? "animate-in-order order-3" : ""}`}>
                <h2 className="text-2xl-semibold color--text-primary mb-1">{totalCashBackPaid}</h2>
                <p className="text-xs-regular color--text-primary opaque-70 mb-0">Cash Back given to renters</p>
              </div>
            </div>
          </div>
          <div className={`content-card animate-in-order ${isMediumScreenStartFrom ? "order-4" : "order-3"}`}>
            <h2 className="text-2xl-semibold color--text-primary mb-1">How is Stake free?</h2>
            <p className="stake-free-description text-lg-regular color--text-primary opaque-70 text-center mb-0">
              Stake helps you pay rent on time and stay longer. Your home covers it, so you get cash back for free!
            </p>
          </div>
          <div className={`content-card d-block animate-in-order ${isMediumScreenStartFrom ? "order-5" : "order-4"}`}>
            <Carousel
              className="reviews"
              interval={null}
              prevIcon={<ArrowLeftCircle />}
              nextIcon={<ArrowRightCircle />}
            >
              {reviewersData?.map((reviewer, index) => {
                return (
                  <Carousel.Item key={index}>
                    <div className="review-item">
                      <div className="reviewer-info">
                        <img
                          className="reviewer-image"
                          src={reviewer?.profileImageLink}
                          alt={reviewer?.profileImageName}
                        />
                        <div className="reviewer-details">
                          <h2 className="text-3xl-regular color--text-primary">{reviewer?.userFullName}</h2>
                          <StarRating value={reviewer?.rating} size="small" />
                          <p className="text-sm-regular color--text-primary opaque-50 mt-2 mb-0">
                            {reviewer?.userStatus}
                          </p>
                        </div>
                      </div>
                      <div className="divider"></div>
                      <p className="review-text text-base-regular color--text-primary mb-0">{reviewer?.reviewString}</p>
                    </div>
                  </Carousel.Item>
                );
              })}
            </Carousel>
          </div>
        </div>
      </div>
      <div className="max-width-container" ref={buttonRef}>
        <PrimaryButton className={`trust-cta-button my-6 ${!isMediumScreenStartFrom ? "order-5" : ""}`} onClick={onCTATrigger}>
          Continue
        </PrimaryButton>
      </div>
    </div>
  );
}

TrustScreen.prototype = { onCTATrigger: PropTypes.func };
