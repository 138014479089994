import { FormProvider, useForm } from "react-hook-form";

export default function withFormProvider(Component, formConfig = {}) {
  return (props) => {
    const formProps = useForm(formConfig);

    return (
      <FormProvider {...formProps}>
        <Component {...props} />
      </FormProvider>
    );
  };
}
