import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { closeSnackbar, SnackbarContent } from "notistack";

import { getToastVariantIcon } from "../../Utils/helpers";

import { CrossMark } from "../../Assets/Icons";

const Toast = React.forwardRef((props, ref) => {
  const { message, variant, alignToAppContent, id } = props;

  const VariantIcon = getToastVariantIcon(variant);

  return (
    <SnackbarContent ref={ref} role="alert">
      <Stack
        className={`generic-toast ${variant} ${alignToAppContent === true ? "align-to-app-content" : ""}`}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack direction="row" spacing={2} alignItems="center">
          <Box className={`variant-icon-wrapper ${variant}`}>
            <VariantIcon className="variant-icon" />
          </Box>
          <Typography className="message">{message}</Typography>
        </Stack>
        <button className="close-button" onClick={closeSnackbar.bind(this, id)}>
          <CrossMark />
        </button>
      </Stack>
    </SnackbarContent>
  );
});

export { Toast };
