import SkeletonLoader from "../../Components/Skeleton/skeleton-loader";

import { Stack } from "@mui/material";

export default function CreditBuilderSkeleton() {
    return (
        <Stack
            className="credit-builder mt-sm-6 mt-5"
            alignItems="center"
            justifyContent="space-between"
            rowGap={6}>
            <Stack alignItems="center" className="row-gap-3 h-100 w-100">
                <SkeletonLoader className="border--rounded-full" width={80} height={12} />
                <Stack alignItems="center" rowGap={1} className="w-100">
                    <SkeletonLoader className="border--rounded-full" width="100%" height={8} sx={{ maxWidth: '340px' }} />
                    <SkeletonLoader className="border--rounded-full" width="100%" height={8} sx={{ maxWidth: '270px' }} />
                </Stack>
            </Stack>
            <SkeletonLoader className="border--rounded-sm button" width="100%" height={48} />
        </Stack>
    );
}