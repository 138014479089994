import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useIntercom } from "react-use-intercom";

import TextField from "../../Components/Form Fields/text-field";
import PersonalInformationSkeleton from "../../Components/Skeleton/personal-information-skeleton";

import renderEditEmail from "../../Organisms/Modals/Personal Information/edit-email";
import renderEditPhoneNumber from "../../Organisms/Modals/Personal Information/edit-phone-number";
import renderUpdateInformation from "../../Organisms/Modals/Personal Information/update-information";

import { useModalContext } from "../../Contexts/ModalContext";

import { useDeals, useUserAccount } from "../../CustomHooks/Queries";

import { transformCountryCodeFormatToUSNumber } from "../../Utils/helpers";

import { ArrowLeftCircle, Edit } from "../../Assets/Icons";

import "./personal-information.scss";

export default function PersonalInformation() {
  const modalContext = useModalContext();

  const navigate = useNavigate();

  const { update } = useIntercom();

  const { isLoading: isLoadingDeals, data: deals } = useDeals();
  const { isLoading: isLoadingUserAccountData, data: userAccountData } = useUserAccount();

  useEffect(() => {
    update({
      userId: userAccountData?.data?.data?.user?._id,
      email: userAccountData?.data?.data?.user?.email,
      phone: userAccountData?.data?.data?.user?.phoneNumber,
    });
  }, [userAccountData, update]);

  const user = useMemo(() => userAccountData?.data?.data?.user ?? {}, [userAccountData]);

  const lastName = useMemo(() => user?.lastName?.charAt(0).toUpperCase() + user?.lastName?.slice(1), [user]);

  const phoneNumber = useMemo(() => transformCountryCodeFormatToUSNumber(user?.phoneNumber ?? ""), [user]);

  const dateOfBirth = useMemo(() => {
    const dateStr = deals?.data?.data?.currentDeal?.birthdate ?? "";
    if (dateStr === "") {
      return "";
    }

    const dateObj = new Date(dateStr);

    // Extract month, day, and year using UTC methods to avoid time zone issues
    const day = dateObj.getUTCDate();
    const month = dateObj.toLocaleString("en-US", { month: "short", timeZone: "UTC" });
    const year = dateObj.getUTCFullYear();

    return `${month} ${day}, ${year}`;
  }, [deals]);

  const sfid = useMemo(() => {
    return deals?.data?.data?.currentDeal?.sfid ?? "";
  }, [deals]);

  const handleEditEmailClick = renderEditEmail.bind(this, modalContext, user?.email, sfid);
  const handleEditPhoneClick = renderEditPhoneNumber.bind(this, modalContext, phoneNumber, sfid);

  return (
    <div className="personal-information">
      <section>
        <div className="max-width-container">
          <div className="section-header with-back-button">
            <button className="go-back-button" onClick={navigate.bind(this, -1)}>
              <ArrowLeftCircle />
            </button>
            <h3 className="section-title">Personal Information</h3>
          </div>
          {isLoadingUserAccountData || isLoadingDeals ? (
            <PersonalInformationSkeleton />
          ) : (
            <div className="form-card">
              <TextField
                className=" mb-sm-6 mb-4"
                label="First Name"
                value={user?.firstName}
                readOnly={true}
                onClick={renderUpdateInformation.bind(this, modalContext, "First Name")}
              />
              <TextField
                className="mb-sm-6 mb-4"
                label="Last Name"
                value={lastName}
                readOnly={true}
                onClick={renderUpdateInformation.bind(this, modalContext, "Last Name")}
              />
              <TextField
                className="mb-sm-6 mb-4"
                label="Date Of Birth"
                value={dateOfBirth}
                readOnly={true}
                onClick={renderUpdateInformation.bind(this, modalContext, "Date Of Birth")}
              />
              <TextField
                className="mb-sm-6 mb-4"
                label="Email"
                value={user?.email}
                readOnly={true}
                RightIcon={Edit}
                onClick={handleEditEmailClick}
                onRightIconClick={handleEditEmailClick}
              />
              <TextField
                className="mb-sm-6 mb-4"
                label="Phone"
                value={phoneNumber}
                readOnly={true}
                RightIcon={Edit}
                onClick={handleEditPhoneClick}
                onRightIconClick={handleEditPhoneClick}
              />
              <TextField
                className="mb-sm-6 mb-4"
                label="Address"
                value={`${user?.address1} ${user?.address2}`}
                readOnly={true}
                onClick={renderUpdateInformation.bind(this, modalContext, "Address")}
              />
              <TextField
                className="mb-sm-6 mb-4"
                label="City"
                value={user?.city}
                readOnly={true}
                onClick={renderUpdateInformation.bind(this, modalContext, "City")}
              />
              <TextField
                className="mb-sm-6 mb-4"
                label="State"
                value={user?.state}
                readOnly={true}
                onClick={renderUpdateInformation.bind(this, modalContext, "State")}
              />
              <TextField
                label="Zip"
                value={user?.zipCode}
                readOnly={true}
                onClick={renderUpdateInformation.bind(this, modalContext, "Zip Code")}
              />
            </div>
          )}
        </div>
      </section>
    </div>
  );
}
