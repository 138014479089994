import { useIMask } from "react-imask";
import { useFormContext } from "react-hook-form";

import TextField from "./text-field";

import { validation } from "../../Utils/validations";

import { ssnMask } from "../../Utils/constants";

import { removeControlCharacters } from "../../Utils/helpers";

export default function SSNField(props) {
  const { register, getFieldState, formState } = useFormContext();

  const ssnFieldProps = register("ssn", {
    required: true,
    validate: (ssn) => validation.SSN.isAValidSSN(ssn) || "Please enter your 9-digit Social Security Number.",
  });

  const { ref } = useIMask(
    {
      mask: ssnMask,
      eager: true,
    },
    {
      onAccept: (value) => ssnFieldProps.onChange?.({ target: { name: "ssn", value: removeControlCharacters(value) } }),
    }
  );

  const { error: ssnFieldError } = getFieldState(ssnFieldProps.name, formState);

  return (
    <TextField
      {...ssnFieldProps}
      ref={(ele) => {
        ref.current = ele;
        ssnFieldProps.ref(ele);
      }}
      onBlur={(e) =>
        ssnFieldProps.onBlur({
          target: { name: "ssn", value: removeControlCharacters(e.target.value) },
        })
      }
      placeholder="Enter Social Security Number"
      autoFocus
      errorMessage={ssnFieldError?.message}
      {...props}
    />
  );
}
