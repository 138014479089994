import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

import { ViewportProvider } from "./Contexts/ViewportContext";
import { CustomSnackbarProvider } from "./Contexts/CustomSnackbarContext";

import Routes from "./routes";

import ScrollToTop from "./Components/Others/scroll-to-top";

import UXCamLib from "./Utils/ux-cam-lib";
import HeapAnalytics from "./Utils/heap-analytics-lib";

import "./App.scss";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

function App() {
  return (
    <div id="portal-target-node">
      <QueryClientProvider client={queryClient}>
        <ViewportProvider>
          <CustomSnackbarProvider>
            <BrowserRouter>
              <ScrollToTop />
              <Routes />
            </BrowserRouter>
          </CustomSnackbarProvider>
        </ViewportProvider>
        <ReactQueryDevtools />
      </QueryClientProvider>
      <UXCamLib />
      <HeapAnalytics />
    </div>
  );
}

export default App;
