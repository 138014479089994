import PrimaryButton from "../../../Components/Buttons/primary-button";

import { modalVariants } from "../../../Utils/constants";

import { ReferAFriendModalImage } from "../../../Assets/Images";

export default function renderReferAFriend(modalContext, referAFriend) {
  const { updateModalComponent, setModalVariant, onModalClose } = modalContext;

  const handleInviteClick = () => {
    if (!!referAFriend?.invite_button_link) {
      // Open the URL in a new tab
      window.open(referAFriend.invite_button_link, "_blank", "noopener,noreferrer");
    } else {
      console.log("Invite button link is not available");
    }
  };

  updateModalComponent(() => (
    <>
      <ReferAFriendModalImage className="coin-image my-sm-5 my-0 mx-auto"/>
      <h3 className="modal-status-title mb-0">
        {referAFriend?.referral_cta_page_header ?? "$15 for you. $5 for a friend."}
      </h3>
      <span className="modal-content-text mb-6 mt-2">
        {referAFriend?.referral_cta_page_body ??
          "Invite your friends to Stake Visa Debit. You’ll automatically earn when they’re approved and activate their card"}
      </span>
      <div className="action-container">
        <PrimaryButton className="blue-theme" onClick={handleInviteClick}>
          Invite
        </PrimaryButton>
        <PrimaryButton className="secondary-colors" onClick={onModalClose}>
          Exit
        </PrimaryButton>
      </div>
    </>
  ));
  setModalVariant(modalVariants.infoDialog);
}
