import PropTypes from "prop-types";
import { SnackbarProvider } from "notistack";

import { Toast } from "../Components/Toast/toast";

import { ToastVariants } from "../Utils/constants";

const ToastVariantMapping = Object.fromEntries(ToastVariants.map((variant) => [variant, Toast]));

export function CustomSnackbarProvider({ children }) {
  return (
    <SnackbarProvider
      //domRoot prop essential for aligning snackbar to content center
      domRoot={document.querySelector("#portal-target-node")}
      Components={ToastVariantMapping}
      anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      preventDuplicate={true}
    >
      {children}
    </SnackbarProvider>
  );
}

CustomSnackbarProvider.prototype = {
  children: PropTypes.node,
};
