import { useMemo } from "react";
import { useQueryClient } from "react-query";
import { CircularProgress } from "@mui/material";

import ModalSkeleton from "../../../Components/Skeleton/modal-skeleton";
import PrimaryButton from "../../../Components/Buttons/primary-button";

import { useUserAccount } from "../../../CustomHooks/Queries";
import { useRequestStakeDebitCard } from "../../../CustomHooks/Mutations";

import { dispatchAPIErrorToast, dispatchSuccessToast } from "../../../Utils/helpers";
import { APIQueryKeys, modalVariants } from "../../../Utils/constants";

import { SVD_Single_Card } from "../../../Assets/Images";

export default function renderRequestPhysicalCard(modalContext, { onCancel }) {
  const { updateModalComponent, setModalVariant, setModalPromoComponent, setModalTitle, onModalClose } = modalContext;

  updateModalComponent(() => {
    const queryClient = useQueryClient();

    const { isLoading: isLoadingUserAccount, data: userAccount } = useUserAccount();

    const { isLoading: isRequestingSVD, mutate: RequestStakeDebitCard } = useRequestStakeDebitCard({
      onSuccess: () => {
        queryClient.refetchQueries(APIQueryKeys.stakeDebit);
        dispatchSuccessToast("Your Physical Card is on its Way!");
        onModalClose();
      },
      onError: () => dispatchAPIErrorToast(),
    });

    const user = userAccount?.data?.data?.user;

    const cityStateZipCode = useMemo(() => `${user?.city}, ${user?.state} ${user?.zipCode}`, [user]);

    return isLoadingUserAccount ? (
      <ModalSkeleton />
    ) : (
      <>
        <img className="modal-image" src={SVD_Single_Card} alt="" />
        <span className="modal-content-text mt-6">We’re sending your card to:</span>
        <span className="modal-content-text highlight mt-2">
          {user?.firstName} {user?.lastName}
        </span>
        <span className="modal-content-text highlight mt-1">{user?.address1}</span>
        {!!user?.address2 && user?.address2.length > 0 && (
          <span className="modal-content-text highlight mt-1">{user?.address2}</span>
        )}
        <span className="modal-content-text highlight mt-1 mb-6">{cityStateZipCode}</span>
        <div className="action-container">
          <PrimaryButton onClick={RequestStakeDebitCard} disabled={isRequestingSVD}>
            Confirm {isRequestingSVD && <CircularProgress />}
          </PrimaryButton>
          <PrimaryButton className="secondary-colors" onClick={onCancel}>
            Cancel
          </PrimaryButton>
        </div>
      </>
    );
  });
  setModalPromoComponent(null);
  setModalTitle("");
  setModalVariant(modalVariants.infoDialog);
}
