import { useEffect, useMemo, useRef, useState } from "react";

import renderAstraSignUpSuccess from "../Organisms/Modals/Banking/astra-sign-up-success";

import { useModalContext } from "../Contexts/ModalContext";

import {
  useCreateAstraAccount,
  useCreateUserIntent,
  useRefreshAstraTokens,
  useEnableCardToCardPayment,
  useGetAstraTokens,
} from "./Mutations";

import { useAstraCards, useDeals, useStakeDebit, useUserAccount } from "./Queries";

import { openAstraPortal as openAstraPortalPopUp } from "../Utils/astra-lib";
import { getFetchAstraTokenPayload } from "../Utils/api-payload-generators";
import {
  LocalStorage,
  getPublicIPAddress,
  formatBirthDate,
  extract5DigitZipCode,
  isSVDApproved,
  dispatchAPIErrorToast,
} from "../Utils/helpers";
import { appUtilityKeys, astraActionTypeLinkCard } from "../Utils/constants";

let debounceTimeout = null;

function debounce(callback, delay) {
  if (debounceTimeout) {
    clearTimeout(debounceTimeout);
  }

  debounceTimeout = setTimeout(() => {
    callback();
    debounceTimeout = null;
  }, delay);
}

export default function useAstra() {
  const checkIfFirstCardIsAvailable = useRef(false);
  const [isInitialisingAstra, setIsInitialisingAstra] = useState(true);
  const [isValidAstraToken, setIsValidAstraToken] = useState(false);

  const modalContext = useModalContext();

  const { isLoading: isLoadingDeals, data: deals, refetch: RefetchDeals } = useDeals();
  const { isLoading: isLoadingStakeDebit, data: stakeDebit } = useStakeDebit();
  const { isLoading: isLoadingUserAccount, data: userAccount } = useUserAccount();

  const initAstra = () => {
    const isInstantTransferEnabled = deals?.data?.data?.currentDeal?.enabled_for_instant_transfer__c;

    if (isInstantTransferEnabled) {
      const astraUserIntentId = deals?.data?.data?.currentDeal?.astra_user_intent_id__c;
      const astraAccessToken = deals?.data?.data?.currentDeal?.astra_access_token__c;
      const refreshAccessToken = deals?.data?.data?.currentDeal?.astra_refresh_token__c;

      let lastAstraKeyFetch = LocalStorage.read(appUtilityKeys.lastAstraTokenFetchTime);
      const TOKEN_VALIDITY = 3600 * 1000; // 1 hour in milliseconds

      !!astraAccessToken && LocalStorage.write(appUtilityKeys.astraToken, astraAccessToken);
      !!refreshAccessToken && LocalStorage.write(appUtilityKeys.astraRefreshToken, refreshAccessToken);
      !!astraUserIntentId && LocalStorage.write(appUtilityKeys.astraIntentId, astraUserIntentId);

      if (!isRefreshingAstraTokens) {
        if (!!astraAccessToken && (!lastAstraKeyFetch || Date.now() - parseInt(lastAstraKeyFetch) > TOKEN_VALIDITY)) {
          !isInitialisingAstra && setIsInitialisingAstra(true);
          refreshAstraTokens(getFetchAstraTokenPayload());
        } else if (!!astraAccessToken) {
          setIsValidAstraToken(true);
          setIsInitialisingAstra(false);
        } else if (!astraAccessToken) {
          setIsInitialisingAstra(false);
        }
      }

      if (!isCreatingUserIntent && !astraUserIntentId) {
        initiateAstraUserIntentCreation();
      }
    } else {
      setIsInitialisingAstra(false);
    }
  };

  useEffect(() => {
    if (!isLoadingDeals && !isLoadingStakeDebit && !isLoadingUserAccount) {
      initAstra();
    }
    //eslint-disable-next-line
  }, [isLoadingDeals, isLoadingStakeDebit, isLoadingUserAccount]);

  useEffect(() => {
    const isInstantTransferEnabled = deals?.data?.data?.currentDeal?.enabled_for_instant_transfer__c;

    if (
      isValidAstraToken &&
      !isLoadingDeals &&
      !isLoadingStakeDebit &&
      !isLoadingUserAccount &&
      isInstantTransferEnabled
    ) {
      const astraAccountId = deals?.data?.data?.currentDeal?.astra_account_id__c;
      const IsSVDApproved = isSVDApproved(stakeDebit.data);

      if (!isCreatingAstraAccount && IsSVDApproved && !astraAccountId) {
        initiateAstraAccountCreation();
      }
    }
    //eslint-disable-next-line
  }, [isLoadingDeals, isLoadingStakeDebit, isLoadingUserAccount, isValidAstraToken]);

  const onGenerateTokenSettled = () => isInitialisingAstra && setIsInitialisingAstra(false);

  const onGenerateTokenSuccess = (data) => {
    LocalStorage.write(appUtilityKeys.astraToken, data?.data?.data?.access_token ?? "");
    LocalStorage.write(appUtilityKeys.astraRefreshToken, data?.data?.data?.refresh_token ?? "");
    LocalStorage.write(appUtilityKeys.lastAstraTokenFetchTime, Date.now().toString());
    setIsValidAstraToken(!!data?.data?.data?.access_token);
    RefetchDeals();
    RefetchAstraCards();
  };

  const { isLoading: isRefreshingAstraTokens, mutate: refreshAstraTokens } = useRefreshAstraTokens({
    onSuccess: onGenerateTokenSuccess,
    onError: (error) => console.error("Error refreshing Astra tokens:", error),
    onSettled: onGenerateTokenSettled,
  });

  const { mutate: generateAstraTokens } = useGetAstraTokens({
    onSuccess: onGenerateTokenSuccess,
    onError: (err) => console.error(`Error obtaining the astra tokens`, err),
    onSettled: onGenerateTokenSettled,
  });

  const { isLoading: isCreatingUserIntent, mutate: createUserIntent } = useCreateUserIntent({
    onSuccess: (data) => {
      LocalStorage.write(appUtilityKeys.astraIntentId, data?.data?.data?.id);
      RefetchDeals();
    },
    onError: (error) => console.error("Unable to create astra user intent:", error),
  });

  const { isLoading: isCreatingAstraAccount, mutate: createAstraAccount } = useCreateAstraAccount({
    onSuccess: () => RefetchDeals(),
    onError: (error) => console.error("Error creating astra account:", error),
  });

  const {
    isLoading: isLoadingAstraCards,
    isFetching: isFetchingAstraCards,
    data: astraCardsAPIData,
    refetch: RefetchAstraCards,
  } = useAstraCards({
    enabled: isValidAstraToken,
    onSuccess: ({ data }) => {
      if (checkIfFirstCardIsAvailable.current) {
        if (astraDebitCards?.length === 0 && data?.cards?.length === 1) {
          renderAstraSignUpSuccess(modalContext);
        }
        checkIfFirstCardIsAvailable.current = false;
      }
    },
  });

  const { mutate: enableCardToCardPayment } = useEnableCardToCardPayment({
    onSuccess: () => RefetchAstraCards(),
    onError: (error) => console.error("Unable to activate astra debit card", error),
  });

  useEffect(() => {
    if (isLoadingAstraCards || isLoadingStakeDebit) {
      const astraCards = astraCardsAPIData?.data?.cards ?? [];

      const unitCardId = stakeDebit?.data?.data?.card_id ?? "";
      const unitLast4 = stakeDebit?.data?.data?.card_last_4;
      const unitVirtualCardId = stakeDebit?.data?.data?.virtual_card_id ?? "";
      const virtualUnitLast4 = stakeDebit?.data?.data?.virtual_card_last_4;

      const noPhysicalCard = astraCards?.every((card) => card.last_four_digits !== unitLast4);
      const noVirtualCard = astraCards?.every((card) => card.last_four_digits !== virtualUnitLast4);

      debounce(() => {
        if (noVirtualCard && virtualUnitLast4) {
          enableCardToCardPayment(unitVirtualCardId);
        }

        if (noPhysicalCard && unitLast4) {
          enableCardToCardPayment(unitCardId);
        }
      }, 5000);
    }
    //eslint-disable-next-line
  }, [isLoadingAstraCards, isLoadingStakeDebit, astraCardsAPIData, stakeDebit]);

  const astraDebitCards = useMemo(() => {
    if (isLoadingAstraCards || isLoadingStakeDebit) {
      return [];
    }

    const astraCards = astraCardsAPIData?.data?.cards ?? [];

    const unitLast4 = stakeDebit?.data?.data?.card_last_4;
    const virtualUnitLast4 = stakeDebit?.data?.data?.virtual_card_last_4;

    return (
      astraCards
        ?.map((card) => ({
          ...card,
          cardNickName: `${card.card_company} (...${card.last_four_digits})`,
          is_system_card: card.last_four_digits === unitLast4 || card.last_four_digits === virtualUnitLast4,
        }))
        ?.sort((a, b) => new Date(a.created) - new Date(b.created)) ?? []
    );
  }, [isLoadingAstraCards, isLoadingStakeDebit, astraCardsAPIData, stakeDebit]);

  const astraPortalEvents = {
    onCodeReturned: (code) => generateAstraTokens(getFetchAstraTokenPayload(code)),
    onClose: () => {
      checkIfFirstCardIsAvailable.current = true;
      RefetchAstraCards();
    },
    onError: () => dispatchAPIErrorToast({ key: "openAstraPortal" }),
  };

  const openAstraPortal = () => openAstraPortalPopUp(astraActionTypeLinkCard, astraPortalEvents);

  const initiateAstraUserIntentCreation = async () => {
    const ipAddress = await getPublicIPAddress();
    const ssnLast4 = stakeDebit?.data?.data?.data?.ssn_last_4 ?? "";
    const user = userAccount?.data?.data?.user;
    const formattedBirthdate = formatBirthDate(deals?.data?.data?.currentDeal?.birthdate);
    const zipCode = extract5DigitZipCode(user.zipCode);

    const formData = new FormData();
    formData.append("user_id", user._id);
    formData.append("email", user.email);
    formData.append("phone", user.phoneNumber);
    formData.append("first_name", user.firstName);
    formData.append("last_name", user.lastName);
    formData.append("address1", user.address1);
    formData.append("address2", user.address2 || "");
    formData.append("city", user.city);
    formData.append("state", user.state);
    formData.append("postal_code", zipCode);
    formData.append("date_of_birth", formattedBirthdate);
    formData.append("ip_address", ipAddress || "");
    formData.append("ssn", ssnLast4);

    createUserIntent(formData);
  };

  const initiateAstraAccountCreation = () => {
    const user = userAccount?.data?.data?.user;

    const formData = new FormData();
    formData.append("user_id", user._id);
    createAstraAccount(formData);
  };

  return {
    isInitialisingAstra,
    isLoadingAstraCards,
    isFetchingAstraCards,
    isCreatingUserIntent,
    astraDebitCards,
    RefetchAstraCards,
    openAstraPortal,
  };
}
