import PropTypes from "prop-types";

import { ArrowRight } from "../../Assets/Icons";

export default function CardButton({ className = "", title, Icon, onClick }) {
  return (
    <button className={`card-button ${className}`} onClick={onClick}>
      <div className="content">
        <Icon className="icon" />
        <span className="title">{title}</span>
      </div>
      <ArrowRight className="chevron" />
    </button>
  );
}

CardButton.prototype = {
  title: PropTypes.string.isRequired,
  Icon: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
};
