import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress, Stack } from "@mui/material";

import { useStakeDebitStatements } from "../../CustomHooks/Queries";
import { useDownloadStatement } from "../../CustomHooks/Mutations";

import { dispatchAPIErrorToast } from "../../Utils/helpers";

import { ArrowLeftCircle, ArrowRightCircle } from "../../Assets/Icons";

import "./bank-statements.scss";
import "./bank-statements-responsive.scss";

const StatementItem = ({ year, month, onClick, isDownloading }) => {
  return (
    <div className="statement-item" onClick={onClick}>
      <div className="statement-details">
        <span className="year">{year}</span>
        <span className="month">{month}</span>
      </div>
      {isDownloading ? <CircularProgress /> : <ArrowRightCircle className="elaborate-icon" />}
    </div>
  );
};

export default function BankStatements() {
  const navigate = useNavigate();

  const { isLoading: isLoadingStatements, data: debitStatements } = useStakeDebitStatements();

  const {
    isLoading: isDownloadingStatement,
    variables: statementId,
    mutate: requestDownloadStatement,
  } = useDownloadStatement({
    onSuccess: (response) => {
      const url = window.URL.createObjectURL(new Blob([response.data], { type: "application/pdf" }));
      window.open(url, "_blank"); // Open the PDF in a new tab
    },
    onError: () => dispatchAPIErrorToast(),
  });

  const statementItems = useMemo(() => {
    // const mockData = `{"data":{"success":true,"status_code":200,"data":{"data":[{"type":"accountStatementDTO","id":"40305706","attributes":{"period":"2024-04"}},{"type":"accountStatementDTO","id":"43451533","attributes":{"period":"2024-05"}},{"type":"accountStatementDTO","id":"46755588","attributes":{"period":"2024-06"}},{"type":"accountStatementDTO","id":"50237282","attributes":{"period":"2023-07"}},{"type":"accountStatementDTO","id":"50237282","attributes":{"period":"2023-06"}},{"type":"accountStatementDTO","id":"50237282","attributes":{"period":"2024-07"}},{"type":"accountStatementDTO","id":"53906863","attributes":{"period":"2024-08"}}]}}}`;
    // const mockObject = JSON.parse(mockData);
    const statements = debitStatements?.data?.data?.data || [];

    if (statements.length === 0) {
      return null;
    }

    // Sort the statements by period in descending order
    statements.sort((a, b) => {
      const periodA = new Date(a.attributes.period);
      const periodB = new Date(b.attributes.period);
      return periodB - periodA; // Descending order
    });

    return statements.map((statement, index) => {
      // Extract the period value
      const period = statement.attributes.period;
      const [year, month] = period.split("-");
      const date = new Date(year, month - 1); // month - 1 because months are 0-indexed
      const monthName = date.toLocaleString("default", { month: "long" });

      // Capitalize the first letter of the month name
      const formattedMonth = monthName.charAt(0).toUpperCase() + monthName.slice(1);

      return (
        <StatementItem
          key={index}
          year={year}
          month={formattedMonth}
          onClick={() => {
            requestDownloadStatement(statement.id);
          }}
          isDownloading={isDownloadingStatement && statement.id === statementId}
        />
      );
    });
  }, [requestDownloadStatement, isDownloadingStatement, debitStatements, statementId]);

  return (
    <div className="bank-statement">
      <section>
        <div className="max-width-container">
          <div className="section-header with-back-button">
            <ArrowLeftCircle className="go-back-button" onClick={() => navigate(-1)} />
            <h3 className="section-title">Statements</h3>
          </div>
          {isLoadingStatements ? (
            <div className="loading-content">
              <CircularProgress />
            </div>
          ) : !!statementItems ? (
            <div className="statements">{statementItems}</div>
          ) : (
            <Stack className="no-record" alignContent="center" justifyContent="center">
              <div className="text-xl-medium text-center">No bank statements available</div>
            </Stack>
          )}
        </div>
      </section>
    </div>
  );
}
