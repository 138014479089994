import { ConnectedAccounts } from "../../../Pages/Connected Accounts";

import { modalVariants } from "../../../Utils/constants";

export default function renderSelectConnectedAccounts(modalContext, props) {
  const { updateModalComponent, setModalVariant } = modalContext;

  updateModalComponent(() => <SelectConnectedAccounts presentedForSelection {...props} />);
  setModalVariant(modalVariants.pageAsModalComponentDialog);
}

export const SelectConnectedAccounts = (props) => {
  return <ConnectedAccounts {...props} />;
};
