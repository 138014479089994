import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";

import UnclaimedBackground from "./unclaimed-background";
import UnclaimedListCard from "./unclaimed-list-card";
import AmountOdometer from "../../Components/Others/amount-odometer";
import PrimaryButton from "../../Components/Buttons/primary-button";

import { useUnclaimedTransactions } from "../../CustomHooks/Queries";

import { useClaimUnclaimedTransactions } from "../../CustomHooks/Mutations";

import { useViewport } from "../../Contexts/ViewportContext";

import { dispatchAPIErrorToast, formatCurrency, getNumberFromCurrentAmount } from "../../Utils/helpers";
import { mediumScreenStartFrom } from "../../Utils/constants";

import { StakeLogo } from "../../Assets/Images";

import "./unclaimed.scss";
import "./unclaimed-responsive.scss";

export default function Unclaimed() {
  const { width } = useViewport();

  const navigate = useNavigate();

  const { data: unclaimedTransactionsData } = useUnclaimedTransactions();

  const { isLoading: isClaimingCashback, mutate: claimCashback } = useClaimUnclaimedTransactions({
    onSuccess: navigate.bind(this, "/banking"),
    onError: () => dispatchAPIErrorToast({ key: "claimCashback" }),
  });

  const totalCashBack = useMemo(
    () => getNumberFromCurrentAmount(unclaimedTransactionsData?.data?.data?.total_cash_back_unlocked ?? "$0"),
    [unclaimedTransactionsData?.data?.data?.total_cash_back_unlocked]
  );

  return (
    <div className="unclaimed">
      <UnclaimedBackground />
      <div className="page-content">
        <header>
          <StakeLogo className="logo dark" />
        </header>
        <div className="unclaimed-content max-width-container mb-auto">
          <div className="unclaimed-total-card mb-4">
            <p className="text-lg-regular color--charcoal opaque-60 m-0">You have</p>
            <AmountOdometer
              className="my-1"
              value={totalCashBack}
              size={width > mediumScreenStartFrom ? "48px" : "40px"}
              fontWeight={600}
              color="var(--charcoal)"
            />
            <p className="text-lg-regular color--charcoal opaque-60 m-0">unclaimed Cash Back</p>
          </div>
          <h2 className="text-xl-semibold color--charcoal m-0">You are claiming:</h2>
          <UnclaimedListCard>
            {unclaimedTransactionsData?.data?.data?.unclaimed_transactions.map((unclaimed, idx) => (
              <div key={idx} className="unclaimed-list-item">
                <div className="unclaimed-info">
                  <h4 className="reason">{unclaimed.description}</h4>
                  <p className="date">{unclaimed.date_string}</p>
                </div>
                <p className="amount">+{formatCurrency(unclaimed.amount)}</p>
              </div>
            ))}
          </UnclaimedListCard>
        </div>
        <div className="max-width-container overflow-hidden">
          <PrimaryButton className="claim-cta-button my-6" onClick={claimCashback} disabled={isClaimingCashback}>
            Claim Now {isClaimingCashback && <CircularProgress />}
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
}
