import { useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";

import renderStatusModal, { StatusModal } from "../status-modal";

import { useClearRecurringDeposit } from "../../../CustomHooks/Mutations";

import { useModalContext } from "../../../Contexts/ModalContext";

import {
  modalVariants,
  FailedRecurringDepositStatusModalProps,
  SuccessClearRecurringDepositStatusModalProps,
  APIQueryKeys,
} from "../../../Utils/constants";

export default function renderClearRecurringDepositFailed(modalContext) {
  const { updateModalComponent, setModalVariant, setModalTitle } = modalContext;

  updateModalComponent(() => <ClearRecurringDepositFailed />);
  setModalVariant(modalVariants.statusDialog);
  setModalTitle("");
}

export const ClearRecurringDepositFailed = () => {
  const modalContext = useModalContext();
  const { onModalClose } = modalContext;

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const { isLoading: isClearingRecurringDeposit, mutate: ClearRecurringDeposit } = useClearRecurringDeposit({
    onSuccess: () => {
      queryClient.refetchQueries(APIQueryKeys.recurringDeposit);
      renderStatusModal(modalContext, {
        ...SuccessClearRecurringDepositStatusModalProps,
        onCTAButtonClick: () => onModalClose(),
        onSecondaryCTAButtonClick: () => {
          navigate("/banking");
          onModalClose();
        },
      });
    },
  });

  return (
    <StatusModal
      {...FailedRecurringDepositStatusModalProps}
      isCTAButtonLoading={isClearingRecurringDeposit}
      onCTAButtonClick={() => ClearRecurringDeposit()}
      onSecondaryCTAButtonClick={() => onModalClose()}
    />
  );
};
