import SkeletonLoader from "../../Components/Skeleton/skeleton-loader";

export default function BankAccountInformationSkeleton() {
    return (
        <SkeletonLoader
            className="border--rounded-lg bg--loader-bg-dark-secondary banking-account-info skeleton"
            width="100%"
            height={232}
        />
    );
}