import { useIntercom } from "react-use-intercom";

import PrimaryButton from "../../../Components/Buttons/primary-button";

import { modalVariants } from "../../../Utils/constants";

export default function renderUpdateInformation(modalContext, informationName = "") {
  const { updateModalComponent, setModalVariant, setModalTitle, onModalClose } = modalContext;

  updateModalComponent(() => {
    const { show: showIntercom } = useIntercom();

    return (
      <>
        <span className="modal-content-text my-6">
          Please contact us to change your {informationName?.toLowerCase()} so we can make sure it matches your lease.
        </span>
        <div className="action-container">
          <PrimaryButton
            className="blue-theme"
            onClick={() => {
              showIntercom();
              onModalClose();
            }}
          >
            Send us a message
          </PrimaryButton>
          <PrimaryButton className="secondary-colors" onClick={onModalClose}>
            Cancel
          </PrimaryButton>
        </div>
      </>
    );
  });
  setModalVariant(modalVariants.infoDialog);
  setModalTitle(`Update ${informationName}`);
}
