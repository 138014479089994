import { useEffect, useMemo, useState } from "react";
import { Divider } from "@mui/material";

import AmountOdometer from "../../Components/Others/amount-odometer";

import renderAvailableBalance from "../../Organisms/Modals/Banking/available-balance";
import renderCurrentBalance from "../../Organisms/Modals/Banking/current-balance";

import { useModalContext } from "../../Contexts/ModalContext";
import { useViewport } from "../../Contexts/ViewportContext";

import { formatNumberWithCentsToUSD, getAnimateCurrentBalanceFromKey, isSVDApproved, LocalStorage } from "../../Utils/helpers";

import { mediumScreenStartFrom } from "../../Utils/constants";

import { Help, Eye, EyeSlash } from "../../Assets/Icons";

export default function BankAccountInformation({ currentBalance, availableBalance, stakeDebit = {} }) {
  const { width } = useViewport();

  const modalContext = useModalContext();

  const [showFullAccount, setShowFullAccount] = useState(false);
  const toggleAccountVisibility = () => {
    setShowFullAccount(!showFullAccount);
  };

  const formattedAvailableBalance = formatNumberWithCentsToUSD(availableBalance);

  const animateCurrentBalanceFrom = useMemo(
    () => parseFloat(LocalStorage.read(getAnimateCurrentBalanceFromKey()) ?? 0),
    []
  );

  useEffect(() => {
    const timeout = setTimeout(() => LocalStorage.write(getAnimateCurrentBalanceFromKey(), currentBalance), 2000);
    return () => clearTimeout(timeout);
  }, [currentBalance]);

  const IsSVDApproved = useMemo(() => {
    return isSVDApproved(stakeDebit?.data);
  }, [stakeDebit?.data]);

  const accountNumber = useMemo(() => {
    const fullNumber = stakeDebit?.data?.data?.account_number || "XXXXXXXXXXXX";
    return showFullAccount ? fullNumber : `...${fullNumber.slice(-4)}`;
  }, [stakeDebit?.data?.data?.account_number, showFullAccount]);

  const routingNumber = useMemo(() => {
    return stakeDebit?.data?.data?.routing_number ?? "012341239";
  }, [stakeDebit?.data?.data?.routing_number]);

  let accountInformation = <></>;

  if (IsSVDApproved) {
    accountInformation = (
      <>
        <Divider />
        <div className="account-info">
          <div className="account-number">
            <span className="label">Account</span>
            <span className="value">
              {accountNumber}
              <button className="icon" onClick={toggleAccountVisibility}>
                {showFullAccount ? <EyeSlash /> : <Eye />}
              </button>
            </span>
          </div>
          <Divider orientation="vertical" flexItem />
          <div className="routing-number">
            <span className="label">Routing</span>
            <span className="value">{routingNumber}</span>
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="banking-account-info">
      <div className="balance-container">
        <p className="balance-label">
          Current Balance
          <button className="icon" onClick={renderCurrentBalance.bind(this, modalContext)}>
            <Help />
          </button>
        </p>
        <AmountOdometer
          className="my-1"
          initialValue={animateCurrentBalanceFrom}
          value={currentBalance}
          size={width > mediumScreenStartFrom ? "48px" : "40px"}
          fontWeight={600}
          color="var(--text-primary)"
        />
        {availableBalance > 0 && (
          <p className="available-balance">
            Available balance: {formattedAvailableBalance}
            <button className="icon" onClick={renderAvailableBalance.bind(this, modalContext)}>
              <Help />
            </button>
          </p>
        )}
      </div>

      {accountInformation}
    </div>
  );
}
