import { useQuery } from "react-query";

import { APIhandler } from "../../Utils/api";

import { APIQueryKeys } from "../../Utils/constants";

export const useMobileAppSummary = (config) => {
  return useQuery(APIQueryKeys.mobileAppSummary, () => APIhandler.platformSummary.getMobileAppSummary(), config);
};

export const usePlatformSummaryStats = (config) => {
  return useQuery(
    APIQueryKeys.platformSummaryStats,
    () => APIhandler.platformSummary.getPlatformSummaryStats(),
    config
  );
};

export const useDeals = (config) => {
  return useQuery(APIQueryKeys.deals, () => APIhandler.deals.requestDeals(), config);
};

export const useUnclaimedTransactions = (config) => {
  return useQuery(
    APIQueryKeys.unclaimedTransactions,
    () => APIhandler.unclaimedTransactions.getUnclaimedTransactions(),
    config
  );
};

export const useTransferToken = (config) => {
  return useQuery(APIQueryKeys.transferToken, () => APIhandler.tokens.getTransferToken(), config);
};

export const useAlternativeLoginFlag = (config) => {
  return useQuery(
    APIQueryKeys.alternativeLoginFlag,
    () => APIhandler.alternativeLogin.getAlternativeLoginFlag(),
    config
  );
};

export const useConnectedAccounts = (config) => {
  return useQuery(
    APIQueryKeys.connectedAccounts,
    (data) => APIhandler.bankConnections.getConnectedAcccounts(data),
    config
  );
};

export const useConnectedAccountSelection = (config) => {
  return useQuery(
    APIQueryKeys.connectedAccountsSelection,
    (data) => APIhandler.bankConnections.getConnectedAcccountSelection(data),
    config
  );
};

export const useStakeDebit = (config) => {
  return useQuery(APIQueryKeys.stakeDebit, () => APIhandler.stakeVisaDebit.getStakeDebit(), config);
};

export const useStakeDebitStatements = (config) => {
  return useQuery(APIQueryKeys.stakeDebitStatements, () => APIhandler.stakeVisaDebit.getStakeDebitStatements(), config);
};

export const usePayrollAccounts = (config) => {
  return useQuery(APIQueryKeys.payrollAccount, () => APIhandler.payroll.getPayrollAccounts(), config);
};

export const useCreditReporting = (config) => {
  return useQuery(APIQueryKeys.creditReporting, () => APIhandler.creditBuilder.getCreditReporting(), config);
};

export const usePaymentHistories = (config) => {
  return useQuery(APIQueryKeys.paymentHistories, () => APIhandler.creditBuilder.getPaymentHistories(), config);
};

export const useUserAccount = (config) => {
  return useQuery(APIQueryKeys.userAccount, () => APIhandler.resident.getUserAccount(), config);
};

export const useActivities = (config) => {
  return useQuery(APIQueryKeys.activities, () => APIhandler.ledger.getActivities(), config);
};

export const useSendVerificationCodeUsingUniqueID = (uniqueContactID, config) => {
  return useQuery(APIQueryKeys.mobileAppSummary, () => APIhandler.login.sendVerificationCode(uniqueContactID), config);
};

export const useCustomerTokenVerificationSMS = (config) => {
  return useQuery(
    APIQueryKeys.customerTokenVerificationSMS,
    () => APIhandler.stakeVisaDebit.getCustomerTokenVerificationSMS(),
    config
  );
};

// New Astra query endpoints
export const useAstraCards = (config = {}) => {
  return useQuery(APIQueryKeys.astraCards, () => APIhandler.astra.fetchAstraCards(), config);
};

export const useFetchAstraAccounts = (config = {}) => {
  return useQuery(APIQueryKeys.astraAccounts, () => APIhandler.astra.fetchAstraAccounts(), config);
};

export const useCheckRoutesCapabilities = (astraUserId, config = {}) => {
  return useQuery(APIQueryKeys.routesCapabilities, () => APIhandler.astra.checkRoutesCapabilities(astraUserId), config);
};

// Recurring Deposit
export const useGetRecurringDeposit = (config = {}) => {
  return useQuery(APIQueryKeys.recurringDeposit, () => APIhandler.recurringDeposits.getRecurringDeposit(), config);
};
