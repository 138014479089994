import Confetti from "react-confetti";

import { useViewport } from "../../Contexts/ViewportContext";

import { UnclaimedPageBackground } from "../../Assets/Images";

export default function UnclaimedBackground() {
  const { width, height } = useViewport();
  
  return (
    <div className="unclaimed-background">
      <UnclaimedPageBackground className="unclaimed-page-background" />
      <Confetti width={width} height={height} />
    </div>
  );
}
