import { useMemo } from "react";
import { useQueryClient } from "react-query";
import { useIntercom } from "react-use-intercom";

import EarnMore from "../../Organisms/Earn More Card";
import CustomStepper from "../../Components/Others/custom-stepper";

import renderReferAFriend from "../../Organisms/Modals/Banking/refer-a-friend";
import renderApplySVD from "../../Organisms/Modals/SVD/apply-SVD";
import renderVirtualSVDApproved from "../../Organisms/Modals/Virtual Card/virtual-stake-debit-approved";

import useConnectBankAccount from "../../CustomHooks/useConnectBankAccount";

import { useModalContext } from "../../Contexts/ModalContext";

import {
  isSVDPending,
  isSVDCardRequested,
  dispatchAPIErrorToast,
  isSVDDenied,
  hasSVDPhysicalCardArrived,
  formatDate,
  isSVDApproved,
} from "../../Utils/helpers";
import { APIQueryKeys, nilYearLongMonthDateFormat } from "../../Utils/constants";

import { AddABank, ExpressPaycheck, ReferAFriend, StakeVisaDebit } from "../../Assets/Images";

export default function EarnMoreCards({
  connectedAccounts = {},
  stakeDebit = {},
  deals = {},
  payrollAccountsData = {},
  payrollProps = {},
}) {
  const modalContext = useModalContext();
  const { onModalClose } = modalContext;

  const { show: showIntercom } = useIntercom();

  const handleSVDModalOpen = () =>
    renderApplySVD(modalContext, {
      onApproved: () => renderVirtualSVDApproved(modalContext, () => onModalClose()),
      status: stakeDebit?.data?.data?.status,
    });

  const queryClient = useQueryClient();

  const { isSavingAccount, plaidReady, openPlaid } = useConnectBankAccount({
    onSuccess: () => queryClient.refetchQueries(APIQueryKeys.connectedAccounts),
    onError: () => dispatchAPIErrorToast({ alignToAppContent: true }),
  });

  const IsSVDApproved = useMemo(() => isSVDApproved(stakeDebit?.data), [stakeDebit?.data]);

  const {
    isFetchingPayrollAccounts,
    isGettingPayrollLinkToken,
    refetchingPayrollDataWithDebounce,
    GetPayrollLinkToken,
    PinwheelComponent,
  } = payrollProps;

  const isBankAccountConnected = useMemo(() => {
    return connectedAccounts?.data?.data?.accounts?.length > 0;
  }, [connectedAccounts]);

  const IsSVDPending = useMemo(() => {
    return isSVDPending(stakeDebit?.data);
  }, [stakeDebit]);

  const IsSVDCardRequested = useMemo(() => {
    return isSVDCardRequested(stakeDebit?.data);
  }, [stakeDebit]);

  const physicalCardArrivesByDate = useMemo(
    () => new Date(stakeDebit?.data?.data?.physical_card_arrives_by ?? 0),
    [stakeDebit]
  );

  const formattedPhysicalCardArrivesByDate = useMemo(() => {
    return formatDate(physicalCardArrivesByDate, nilYearLongMonthDateFormat);
  }, [physicalCardArrivesByDate]);

  const HasSVDPhysicalCardArrived = useMemo(
    () => hasSVDPhysicalCardArrived(new Date(stakeDebit?.data?.data?.physical_card_arrives_by)),
    [stakeDebit]
  );

  const IsSVDDenied = useMemo(() => {
    return isSVDDenied(stakeDebit?.data);
  }, [stakeDebit]);

  const referAFriend = useMemo(() => {
    return deals?.data?.content?.homescreen?.cards?.find((card) => "refer_a_friend" in card)?.refer_a_friend || null;
  }, [deals]);

  const isPayrollConnected = useMemo(() => {
    return payrollAccountsData?.data?.data?.accounts?.length > 0;
  }, [payrollAccountsData]);

  const earnMoreGuideReferAFriend = useMemo(() => {
    return deals?.data?.content?.earn_more_guide?.cards?.[0]?.refer_a_friend || null;
  }, [deals]);

  return (
    <>
      {IsSVDApproved && !isPayrollConnected && <PinwheelComponent />}
      <div className="earn-more-cards row">
        {!isBankAccountConnected && (
          <div className="col-sm-6 col-12 px-sm-2 px-0">
            <EarnMore
              title="Connect a Bank"
              cardButtonText="Connect"
              description="Transfer in funds to pay rent, make purchases, and earn Cash Back!"
              CardIllustrationComponent={AddABank}
              onCardButtonClick={() => plaidReady && !isSavingAccount && openPlaid()}
              isLoading={!plaidReady || isSavingAccount}
            />
          </div>
        )}

        {IsSVDDenied && (
          <div className="col-sm-6 col-12 px-sm-2 px-0">
            <EarnMore
              title="Application Denied"
              description="Your Stake Checking application was denied."
              CardIllustrationComponent={StakeVisaDebit}
              NestComponent={() => (
                <button className="button" style={{ width: "fit-content" }}>
                  Contact Support
                </button>
              )}
              onCardButtonClick={showIntercom.bind(this)}
            />
          </div>
        )}

        {IsSVDPending && (
          <div className="col-sm-6 col-12 px-sm-2 px-0">
            <EarnMore
              title={stakeDebit?.data?.data?.home_card_header}
              description={stakeDebit?.data?.data?.home_card_body}
              cardButtonText="Resume"
              CardIllustrationComponent={StakeVisaDebit}
              onCardButtonClick={window.open.bind(this, stakeDebit?.data?.data?.application_link ?? "", "_blank")}
            />
          </div>
        )}

        {stakeDebit?.data?.data?.status === "Eligible" && (
          <div className="col-sm-6 col-12 px-sm-2 px-0">
            <EarnMore
              title="Stake Checking"
              description="FDIC-insured with a virtual Stake Visa Debit card. Unlock Cash Back on rent and purchases"
              cardButtonText="Get started"
              CardIllustrationComponent={StakeVisaDebit}
              onCardButtonClick={handleSVDModalOpen.bind(this)}
            />
          </div>
        )}

        {IsSVDCardRequested && (
          <div className="col-sm-6 col-12 px-sm-2 px-0">
            <EarnMore
              title={stakeDebit?.data?.data?.home_card_header}
              description={stakeDebit?.data?.data?.home_card_body ?? "Call (833)-333-0417 to activate your card."}
              CardIllustrationComponent={StakeVisaDebit}
              NestComponent={() => (
                <CustomStepper
                  steps={["Approved", `Arrives by ${formattedPhysicalCardArrivesByDate}`, "Activate your card"]}
                  activeStep={!HasSVDPhysicalCardArrived ? 1 : 2}
                  orientation="vertical"
                  alternativeLabel={false}
                />
              )}
            />
          </div>
        )}

        <div className="col-sm-6 col-12 px-sm-2 px-0">
          <EarnMore
            title={referAFriend?.homepage_card ?? "Refer a friend"}
            description={earnMoreGuideReferAFriend?.body ?? "Refer 5 friends successfully and get $45"}
            cardButtonText={earnMoreGuideReferAFriend?.call_to_action ?? "Get $45"}
            CardIllustrationComponent={ReferAFriend}
            onCardButtonClick={renderReferAFriend.bind(this, modalContext, referAFriend)}
          />
        </div>

        {IsSVDApproved && !isPayrollConnected && (
          <div className="col-sm-6 col-12 px-sm-2 px-0">
            <EarnMore
              title="Express Paycheck"
              description="It’s your money, get it sooner. Get your paycheck up to two days early."
              cardButtonText="Let’s go!"
              CardIllustrationComponent={ExpressPaycheck}
              onCardButtonClick={() => GetPayrollLinkToken()}
              isLoading={isFetchingPayrollAccounts || refetchingPayrollDataWithDebounce || isGettingPayrollLinkToken}
            />
          </div>
        )}
      </div>
    </>
  );
}
