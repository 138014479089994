import { useRef } from "react";
import { useFormContext } from "react-hook-form";
import { CircularProgress } from "@mui/material";
import { closeSnackbar } from "notistack";

import ModalSkeleton from "../../../Components/Skeleton/modal-skeleton";
import OTPField from "../../../Components/Form Fields/otp-field";
import PrimaryButton from "../../../Components/Buttons/primary-button";

import renderVirtualStakeDebit from "./View Virtual Stake Debit/view-virtual-stake-debit";

import withFormProvider from "../../../HOC/withFormProvider";

import { useModalContext } from "../../../Contexts/ModalContext";

import { useCustomerTokenVerificationSMS } from "../../../CustomHooks/Queries";
import { useCustomerTokenVerificationResponse } from "../../../CustomHooks/Mutations";

import { dispatchErrorToast, dispatchSuccessToast } from "../../../Utils/helpers";
import { modalVariants } from "../../../Utils/constants";

export default function renderVerifyToViewVirtualCard(modalContext) {
  const { updateModalComponent, setModalVariant, setModalTitle } = modalContext;

  updateModalComponent(withFormProvider(VerifyToViewVirtualCard));
  setModalVariant(modalVariants.formDialog);
  setModalTitle("Verify your phone number");
}

const VerifyToViewVirtualCard = () => {
  const resendOtpRef = useRef(false);

  const {
    handleSubmit,
    getFieldState,
    formState: { isValid },
    watch,
  } = useFormContext();

  const modalContext = useModalContext();

  const otp = watch("otp");

  const {
    isFetching: isSendingVerificationCodeForVirtualCard,
    data: customerToken,
    refetch: refetchCustomerToken,
  } = useCustomerTokenVerificationSMS({
    onSuccess: () => {
      if (resendOtpRef.current) {
        resendOtpRef.current = false;
        dispatchSuccessToast("New verification code sent to your phone number", {
          key: "SendVerificationCodeVirtualCard",
        });
      }
    },
    onError: (err) => dispatchErrorToast(err?.response?.data?.error?.msg, { key: "SendVerificationCodeVirtualCard" }),
  });

  const { isLoading: isVerifyingCodeForVirtualCard, mutate: VerifyCodeForVirtualCard } =
    useCustomerTokenVerificationResponse({
      onSuccess: ({ data }) => renderVirtualStakeDebit(modalContext, data?.data),
      onError: () =>
        dispatchErrorToast("Invalid verification code, please try again", {
          key: "VerifyVirtualCard",
        }),
    });

  const onSubmit = () => {
    closeSnackbar();

    const formData = new FormData();

    formData.append("sms", otp);
    formData.append("customer_token_verification", customerToken?.data?.data);

    VerifyCodeForVirtualCard(formData);
  };

  const disableSubmitButton = !getFieldState("otp").isDirty || !isValid || isVerifyingCodeForVirtualCard;

  return isSendingVerificationCodeForVirtualCard ? (
    <ModalSkeleton />
  ) : (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <p className="modal-content-text mt-7 mb-4">To view your card number, please verify your phone number</p>
      <div className="otp-field light-theme">
        <OTPField />
      </div>
      <button
        className="d-block modal-content-text text-decoration-underline mt-4 mb-7 mx-auto"
        type="button"
        onClick={() => {
          resendOtpRef.current = true;
          refetchCustomerToken();
        }}
      >
        Didn’t receive a text? Resend code
      </button>
      <div className="action-container">
        <PrimaryButton className="blue-theme" type="submit" disabled={disableSubmitButton}>
          Next {isVerifyingCodeForVirtualCard && <CircularProgress />}
        </PrimaryButton>
      </div>
    </form>
  );
};
