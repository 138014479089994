import PropTypes from "prop-types";
import { Step, StepLabel, Stepper } from "@mui/material";

const CustomStepper = ({ className, steps, activeStep, orientation, alternativeLabel }) => {
  return (
    <Stepper
      className={`custom-stepper ${className}`}
      activeStep={activeStep}
      orientation={orientation}
      alternativeLabel={alternativeLabel}
    >
      {steps.map((label, idx) => (
        <Step key={idx} className={`${idx + 1 === activeStep ? "active" : ""}`}>
          <StepLabel StepIconComponent={() => <div className="step-icon"></div>}>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

CustomStepper.prototype = {
  className: PropTypes.string,
  steps: PropTypes.array,
  activeStep: PropTypes.number,
  orientation: PropTypes.string,
  alternativeLabel: PropTypes.bool,
};

export default CustomStepper;
