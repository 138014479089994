import SkeletonLoader from "./skeleton-loader";
import { Stack, Divider } from "@mui/material";

export default function BankingActivitySkeleton() {
  return (
    <div className="banking-activity skeleton">
      <Stack className="activity-list" direction="column" divider={<Divider className="activity-divider" />}>
        {Array.from(new Array(3)).map((_, idx) => (
          <div key={idx} className="activity-item">
            <Stack direction="row" alignItems="center" justifyContent="space-between" className="py-2" columnGap={4}>
              <Stack className="gap-2 w-100">
                <SkeletonLoader
                  className="border--rounded-full bg--loader-bg-dark-tertiary"
                  width="100%"
                  height={16}
                  sx={{ maxWidth: "120px" }}
                />
                <SkeletonLoader
                  className="border--rounded-full bg--loader-bg-dark-tertiary"
                  width="100%"
                  height={12}
                  sx={{ maxWidth: "200px" }}
                />
              </Stack>
              <SkeletonLoader className="border--rounded-full bg--loader-bg-dark-tertiary" width={120} height={16} />
            </Stack>
          </div>
        ))}
      </Stack>
    </div>
  );
}
