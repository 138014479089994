import { useState, useRef } from "react";
import { CircularProgress } from "@mui/material";
import { Carousel } from "react-bootstrap";

import PrimaryButton from "../../../Components/Buttons/primary-button";

import { useStakeDebit } from "../../../CustomHooks/Queries";

import { useModalContext } from "../../../Contexts/ModalContext";

import { isSVDApproved } from "../../../Utils/helpers";
import { modalVariants } from "../../../Utils/constants";

import { SVD_Double_Card, SVD_Single_Card } from "../../../Assets/Images";

export default function renderApplySVD(modalContext, props) {
  const { updateModalComponent, setModalVariant, setModalPromoComponent } = modalContext;

  updateModalComponent(() => <ApplySVD {...props} />);
  setModalPromoComponent(null);
  setModalVariant(modalVariants.stepByStepDialog);
}

const ApplySVD = ({ onApproved, status }) => {
  const session = useRef(null);
  const SVDStatus = useRef(status);

  const [renderApplyDialog, setRenderApplyDialog] = useState(false);
  const [applyingSVD, setApplyingSVD] = useState(false);

  const { onModalClose } = useModalContext();

  const { data: stakeDebit } = useStakeDebit({
    refetchOnMount: false,
    refetchOnWindowFocus: applyingSVD,
    onSuccess: ({ data }) => {
      const status = data?.data?.status;
      if (applyingSVD) {
        if (isSVDApproved(data)) {
          onApproved?.();
        } else if (status !== SVDStatus.current) {
          onModalClose();
        } else if (session?.current?.closed) {
          setApplyingSVD(false);
        }
      }
      SVDStatus.current = status;
    },
  });

  const handleNextButtonClick = () => {
    if (!renderApplyDialog) {
      setRenderApplyDialog(true);
    } else {
      const newWindow = window.open(stakeDebit?.data?.data?.application_link ?? "", "_blank");

      if (!newWindow) {
        alert("Failed to open a new tab. Please allow popups.");
      } else {
        session.current = newWindow;
        setApplyingSVD(true);
      }
    }
  };

  return (
    <>
      <Carousel
        className="modal-image-carousel"
        activeIndex={!renderApplyDialog ? 0 : 1}
        interval={null}
        onSelect={(selectedIndex) => setRenderApplyDialog(selectedIndex === 1)}
      >
        {[SVD_Double_Card, SVD_Single_Card]?.map((img_src, index) => {
          return (
            <Carousel.Item key={index}>
              <img src={img_src} alt="" />
            </Carousel.Item>
          );
        })}
      </Carousel>
      <h3 className="modal-status-title mb-0">
        {!renderApplyDialog ? "Earn more Cash Back" : "Stake just got easier"}
      </h3>
      <span className="modal-content-text highlight mt-1">
        {!renderApplyDialog
          ? "1% Cash Back on everything, including rent, forever."
          : "The rewards you know and love, now on a card. No waiting, no fees, and more rewards."}
      </span>
      <div className="action-container mt-6">
        <PrimaryButton onClick={handleNextButtonClick} disabled={applyingSVD}>
          {!renderApplyDialog ? "Next" : applyingSVD ? "Applying..." : "Apply Now"}{" "}
          {applyingSVD && <CircularProgress />}
        </PrimaryButton>
        {renderApplyDialog && !applyingSVD && (
          <PrimaryButton className="secondary-colors" onClick={setRenderApplyDialog.bind(this, false)}>
            Back
          </PrimaryButton>
        )}
      </div>
      {renderApplyDialog && !!stakeDebit?.data?.data?.disclosure_text && (
        <span className="text-sm-regular color--medium-grey text-center mt-4">
          {stakeDebit?.data?.data?.disclosure_text}
        </span>
      )}
    </>
  );
};
