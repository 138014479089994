import CopyToClipboard from "react-copy-to-clipboard";

import PrimaryButton from "../../../Components/Buttons/primary-button";

import { modalVariants } from "../../../Utils/constants";

import { dispatchSuccessToast } from "../../../Utils/helpers";

import { Copy } from "../../../Assets/Icons";

export default function renderAccountDetails(modalContext, stakeDebit = {}) {
  const { updateModalComponent, setModalVariant, setModalTitle, onModalClose } = modalContext;

  const accountNumber = stakeDebit?.data?.account_number ?? "";
  const routingNumber = stakeDebit?.data?.routing_number ?? "";

  updateModalComponent(() => (
    <>
      <div className="account-detail-card my-sm-6 my-5">
        <div className="account-detail">
          <div className="account-detail-label">Account Number</div>
          <div className="account-detail-value">{accountNumber}</div>
        </div>
        <CopyToClipboard
          text={accountNumber}
          onCopy={() => dispatchSuccessToast("Copied to clipboard", { key: "account_number" })}
        >
          <Copy className="clipboard-icon" />
        </CopyToClipboard>
      </div>
      <div className="account-detail-card mb-sm-6 mb-5">
        <div className="account-detail">
          <div className="account-detail-label">Routing Number</div>
          <div className="account-detail-value">{routingNumber}</div>
        </div>
        <CopyToClipboard
          text={routingNumber}
          onCopy={() => dispatchSuccessToast("Copied to clipboard", { key: "account_number" })}
        >
          <Copy className="clipboard-icon" />
        </CopyToClipboard>
      </div>
      <div className="action-container">
        <PrimaryButton className="secondary-colors" onClick={onModalClose}>
          Close
        </PrimaryButton>
      </div>
    </>
  ));
  setModalVariant(modalVariants.infoDialog);
  setModalTitle("Account and Routing Number");
}
