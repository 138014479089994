import { useQueryClient } from "react-query";
import { CircularProgress } from "@mui/material";

import PrimaryButton from "../../../Components/Buttons/primary-button";

import renderVerifyToViewVirtualCard from "./verify-to-view-virtual-card";
import renderStatusModal from "../status-modal";

import { useRequestVirtualStakeDebit } from "../../../CustomHooks/Mutations";

import { useModalContext } from "../../../Contexts/ModalContext";

import { dispatchAPIErrorToast } from "../../../Utils/helpers";
import { APIQueryKeys, modalVariants } from "../../../Utils/constants";

import { CheckCircleOutline } from "../../../Assets/Icons";
import { StakeCardFrontSide } from "../../../Assets/Images";

const SuccessfullyRequestedVirtualCardStatusModalProps = {
  StatusIcon: CheckCircleOutline,
  title: "Activation success!",
  description: 'Access your virtual card from the "banking services" on the banking page',
  CTAButtonText: "View virtual card",
};

export default function renderRequestVirtualCard(modalContext) {
  const { updateModalComponent, setModalVariant, setModalTitle } = modalContext;

  updateModalComponent(() => <RequestVirtualCard />);
  setModalTitle("Manage Virtual Card");
  setModalVariant(modalVariants.stepByStepDialog);
}

const RequestVirtualCard = () => {
  const modalContext = useModalContext();

  const queryClient = useQueryClient();

  const { isLoading: isRequestingVirtualDebit, mutate: RequestStakeVirtualDebit } = useRequestVirtualStakeDebit({
    onSuccess: () => {
      queryClient.refetchQueries(APIQueryKeys.stakeDebit);
      renderStatusModal(modalContext, {
        ...SuccessfullyRequestedVirtualCardStatusModalProps,
        onCTAButtonClick: renderVerifyToViewVirtualCard.bind(this, modalContext),
      });
    },
    onError: () => dispatchAPIErrorToast(),
  });

  return (
    <>
      <img className="stake-card-front-side mt-9" src={StakeCardFrontSide} alt="" />
      <div className="action-container mt-12">
        <PrimaryButton className="blue-theme" onClick={RequestStakeVirtualDebit} disabled={isRequestingVirtualDebit}>
          Activate Card {isRequestingVirtualDebit && <CircularProgress />}
        </PrimaryButton>
      </div>
    </>
  );
};
