import { createContext, useContext, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { CircularProgress } from "@mui/material";

import SideBar from "../Organisms/App Utility Bar/sidebar";
import NavBar from "../Organisms/App Utility Bar/navbar";

import usePrefetchOnLogin from "../CustomHooks/usePrefetchOnLogin";

import { ModalContextProvider } from "./ModalContext";
import { SupportProvider } from "./SupportProvider";

import { appUtilityKeys } from "../Utils/constants";

import { getHeaderBgColorForPage, LocalStorage, saveAndUpdateUserOnLogin } from "../Utils/helpers";

import { StakeLogo } from "../Assets/Images";

const AppUtilityContext = createContext();

export const useAppUtilityContext = () => {
  const appUtilityContextData = useContext(AppUtilityContext);
  if (appUtilityContextData === undefined) {
    throw new Error("useAppUtilityContext must be used within a AppUtilityContextProvider");
  }
  return appUtilityContextData;
};

export const AppUtilityContextProvider = ({ children }) => {
  const [authKey, setAuthKey] = useState(LocalStorage.read(appUtilityKeys.authKey));
  const [appUtilityProps, setAppUtilityProps] = useState({});

  const clearAppUtilityState = (keys = []) =>
    setAppUtilityProps((p) => {
      const t = { ...p };
      keys.forEach((key) => {
        delete t?.[key];
      });
      return t;
    });

  const { isLoading: isPreloadingData, isError: isPrefetchError } = usePrefetchOnLogin({
    authKey,
    hasVisitedWelcomeScreen: true,
  });

  const onEmailOrPhoneNumbeChange = ({ data }) => {
    saveAndUpdateUserOnLogin(data);
    setAuthKey(data?.data?.[appUtilityKeys.authKey]);
  };

  if (!authKey) {
    return <Navigate to="/login" />;
  }

  return (
    <AppUtilityContext.Provider
      value={{ onEmailOrPhoneNumbeChange, appUtilityProps, setAppUtilityProps, clearAppUtilityState }}
    >
      {isPreloadingData || isPrefetchError ? (
        <div className="authenticated-page justify-content-center">
          {isPreloadingData ? (
            <CircularProgress sx={{ color: "var(--charcoal)" }} />
          ) : (
            <span className="text-lg-medium color--charcoal text-center mx-3">
              Something has gone wrong. Please contact support.
            </span>
          )}
        </div>
      ) : (
        <AppUtilityComponents>{!!children ? children : <Outlet />}</AppUtilityComponents>
      )}
    </AppUtilityContext.Provider>
  );
};

const AppUtilityComponents = ({ children }) => {
  const { pathname } = useLocation();

  const headerBgColor = getHeaderBgColorForPage(pathname);

  return (
    <div className="app-utility-container">
      <SupportProvider>
        <ModalContextProvider>
          <SideBar />
          <header className={headerBgColor}>
            <StakeLogo className="logo" />
          </header>
          <div className="app-content">{children}</div>
          <NavBar />
        </ModalContextProvider>
      </SupportProvider>
    </div>
  );
};
