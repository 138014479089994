import React, { useRef } from "react";

import useDetectSticky from "../../CustomHooks/useDetectSticky";

import { monthNames } from "../../Utils/constants";

import { CheckCircleOutline, Close } from "../../Assets/Icons";

export const RentTrackerItem = React.forwardRef(
  ({ paymentList, showReporting, year = "", groupedByYear = false, isSticky = false }, ref) => {
    if (paymentList.length === 0) {
      return <></>;
    }

    return (
      <div ref={ref} className={`rent-tracker-item${isSticky ? " is-sticky" : ""}`}>
        <div className="rent-tracker-header">
          <div className="title">{groupedByYear ? year : "Month"}</div>
          <div className="title text-center">{showReporting && "Reported"}</div>
          <div className="title text-center">On Time</div>
        </div>
        {paymentList.map((payment, index) => (
          <div className="rent-tracker-row" key={index}>
            <div className="text-content">{`${monthNames[parseInt(payment.month__c) - 1] || ""} ${
              !groupedByYear ? payment.year__c ?? "" : ""
            }`}</div>
            <div className="status-icon">
              {showReporting ? payment.reported_to_credit_bureaus__c ? <CheckCircleOutline /> : <Close /> : <></>}
            </div>
            <div className="status-icon">{payment.paid_on_time__c ? <CheckCircleOutline /> : <Close />}</div>
          </div>
        ))}
      </div>
    );
  }
);

export const StickyRentTrackerItem = (props) => {
  const stickyRoot = useRef(document.getElementById("rent-tracker-items"));
  const [isSticky, ref] = useDetectSticky("top", { root: stickyRoot.current });

  return <RentTrackerItem ref={ref} {...props} isSticky={isSticky} />;
};
