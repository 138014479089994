import renderAlertConfirmationModal from "../alert-confirmation-modal";

export function renderPayrollAdditionMaxLimitExceeded(modalContext) {
  const { onModalClose } = modalContext;

  renderAlertConfirmationModal(modalContext, {
    variant: "alert-neutral",
    title: "Payroll Connection Limit Reached",
    description: "You can have up to 5 payroll connections. Please remove an existing connection to add a new one.",
    CTAButtonText: "Close",
    hideSecondaryCTAButton: true,
    onCTAButtonClick: () => onModalClose(),
  });
}
