import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import { isActiveModule } from "../../Utils/helpers";
import { navItems } from "../../Utils/constants";

import SkeletonLoader from "../../Components/Skeleton/skeleton-loader";

export default function NavBar() {
  const { pathname } = useLocation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 2000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="app-utility-navbar">
      {loading ? (
        navItems.map((_, idx) => (
          <div className="navbar-item opacity-100 gap-4" key={idx}>
            <SkeletonLoader className="border--rounded-sm" width={24} height={24} />
            <SkeletonLoader className="border--rounded-full" width={50} height={12} />
          </div>
        ))
      ) : (
        <>
          {navItems.map(({ Icon, navItemName, navRoute }, idx) => (
            <Link
              key={idx}
              className={`navbar-item${isActiveModule(navRoute, pathname) ? " active" : ""
                }`}
              to={navRoute}
            >
              <Icon className="nav-icon" />
              {navItemName}
            </Link>
          ))}
        </>
      )}
    </div>
  );
}
