import PropTypes from "prop-types";
import { Rating } from "@mui/material";

export default function StarRating({ value, size = "medium" }) {
  return (
    <Rating
      size={size}
      value={value}
      precision={0.1}
      readOnly
      icon={<span style={{ fontSize: "inherit" }}>★</span>}
      emptyIcon={<span style={{ fontSize: "inherit" }}>★</span>}
    />
  );
}

StarRating.prototype = { value: PropTypes.number, size: PropTypes.string };
